import React, { useState } from "react"
import MetaTags from 'react-meta-tags'
import { Button, Card, CardBody, CardText, Col, Input, Row } from "reactstrap"

//Import Breadcrumb
import { postWeb } from "helpers/api_helper"
import Pages404 from "pages/Utility/pages-404"
import { useEffect } from "react"
import 'react-loading-skeleton/dist/skeleton.css'
import { useDispatch, useSelector } from "react-redux"
import { searchCustomer } from "store/actions"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import "../../assets/scss/datatables.scss"
import Breadcrumbs from "../../components/Common/Breadcrumb"

const initailStateOfErrors = {
  newMobile:'',
  approvedBy:'',
  reason:'',
  notFound:''
}

const RemoveBag = () => {


  const dispatch = useDispatch()
  const { users, searchedCustomer } = useSelector(state => ({
    users: state.Users.users,
    searchedCustomer: state.Customers.searchedCustomer
  }))

  const [customerData, setCustomerData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [customerID, setCustomerID] = useState("");
  const [error, setError] = useState(initailStateOfErrors);

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])

  useEffect(() => {
    if (searchedCustomer && Object.keys(searchedCustomer).length) {
      setCustomerData(searchedCustomer)
    } else if (customerID && !Object.keys(searchedCustomer).length) {
      setError({ notFound: "Customer details not found" })
    }
    setIsLoading(false);
  }, [searchedCustomer])

  useEffect(() => {
    if (customerID) {
      setCustomerData({}); // Reset customerData whenever searching for a new customer
    }
  }, [customerID]);

  const handleSearch = async () => {
    setError(initailStateOfErrors)
    if (customerID) {
      setCustomerData({});
      setIsLoading(true);
      dispatch(searchCustomer({ customerID }));
      setIsLoading(false);
    } else {
      setCustomerData({})
      setError({ notFound: "Please enter a valid customer ID" })
    }
  }

  const handleChange = (e) => {
    let value = e.target.value;
    // Remove any non-digit characters
    value = value.replace(/[^\d]/g, '');
    if (value === '0') {
      value = ''; // or set it to some default value if needed
    }
    setCustomerID(value);
  };

  const clearCustomerData = () => {
    setCustomerData({});
    setCustomerID('');
    setError(initailStateOfErrors);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };
  const handleRemove = async () => {
    // Reset errors at the beginning
    setError(initailStateOfErrors);
    if(!customerData.bagNo){
      return toastr.error("Bag not found")
    } 
    setIsLoading(true);
    try {
      const response = await postWeb("customers/removeBag", {
        _id: customerData._id,
      });

      if (response.statusCode === "200") {
        toastr.success("Bag removed successfully.");
        setCustomerID('')
       setCustomerData({})
      } else {
        toastr.error(response?.msg || "An unexpected error occurred. Please try again later.");
      }
    } catch (error) {
      toastr.error("An unexpected error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  if (userData?.permissions?.allPermissions == true || userData?.permissions?.supportRemoveBag?.update == true) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Kleen | Remove Bag Number</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Support Tools" breadcrumbItem="Remove Bag Number" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {/* Search tag to search customer ID */}
                    <Row className="mb-3">
                      <Col sm="12">
                        <div className="me-2 mb-2 d-inline-block">
                          <div className="position-relative d-flex gap-1 justify-content-evenly align-items-center">
                            <CardText className="mb-2 pt-2 fs-5 fw-bold">Customer ID: </CardText>
                            <div className="ms-2" style={{ width: "10rem" }}>
                              {/* Input to search customer by Customer ID */}
                              <div className="input-group">
                                <Input
                                // size={'sm'}
                                  type="text"
                                  placeholder="Search Customer"
                                  className="form-control"
                                  value={customerID}
                                  onChange={handleChange}
                                  invalid={error?.notFound.length ? true : false}
                                  onKeyPress={handleKeyPress} // Call handleSearch on Enter key press
                                />
                                {error?.notFound.length ? <div className="text-danger">{error.notFound}</div> : null}
                              </div>
                            </div>

                            <Button
                              className="ms-2"
                              color="primary"
                              onClick={handleSearch}
                              disabled={isLoading}
                              size="sm"
                            >
                              Search
                            </Button>
                            <Button
                              className="ms-2"
                              color="danger"
                              onClick={clearCustomerData}
                              disabled={isLoading}
                              size="sm"
                            >
                              Clear
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {/* Display customers name, current mobile number and input for new number */}
                    {customerData && (!error?.notFound.length && Object.keys(customerData).length > 0) && customerID && <Row>
                      <Col sm="12">
                        <Row>
                          <Col sm="4" className="d-flex gap-1">
                            <CardText className="mb-2 pt-2 fs-5 fw-bold">Customer Name: </CardText>
                            <CardText className="mb-2 pt-2 fs-5">{customerData ? customerData?.name : ""}</CardText>
                          </Col>
                          <Col sm="4" className="d-flex gap-1">
                            <CardText className="mb-2 pt-2 fs-5 fw-bold">Customer Mobile: </CardText>
                            <CardText className="mb-2 pt-2 fs-5">{customerData ? customerData?.mobile : ""}</CardText>
                          </Col>
                         
                        </Row>
                        <Row>
                        <Col sm="4" className="d-flex gap-1  d-flex align-items-center">
                            <CardText className="mb-2 pt-2 fs-5 fw-bold">Bag No: </CardText>
                            <CardText className="mb-2 pt-2 fs-5  d-flex gap-1 align-items-center" style={{ color: customerData?.bagNo ? 'inherit' : 'red' }}>
                              {customerData?.bagNo ? (
                                <div className="d-flex gap-1 align-items-center">
                                  <p className="m-0 p-0">{customerData.bagNo}</p>
                                </div>
                              ) : (
                                "N/A"
                              )}
                            </CardText>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm="4" className="d-flex gap-1">
                          {customerData?.bagNo ? 
                          <Button disabled={isLoading} size="sm" onClick={handleRemove}  color="danger" >
                            Remove Bag
                          </Button> :<span className="text-danger">Bag not found</span>}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  } else {
    return <Pages404 />
  }
}

export default RemoveBag

