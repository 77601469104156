import { ADD_CITY, REMOVE_CITY, UPDATE_CITY, FETCH_CITIES, FETCH_CITIES_SUCCESS, FETCH_CITIES_FAIL } from './actionTypes';

export const addCity = (city) => ({
  type: ADD_CITY,
  payload: city,
});


export const removeCity = (cityId) => ({
  type: REMOVE_CITY,
  payload: cityId,
});

export const updateCity = (city) => ({
  type: UPDATE_CITY,
  payload: city,
});

export const fetchCities = (payload) => ({
  type: FETCH_CITIES,
  payload: payload,
});

export const fetchCitiesSuccess = users => ({
  type: FETCH_CITIES_SUCCESS,
  payload: users,
});

export const fetchCitiesFail = error => ({
  type: FETCH_CITIES_FAIL,
  payload: error,
});


