import { post, postWeb } from 'helpers/api_helper';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { withTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { Button, Form, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import toastr from 'toastr';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { getVehicles, fetchCities } from 'store/actions';
import PagesNoPermission from 'pages/Utility/pages-no-permission';
import Switch from "react-switch"


const initialState = {
  // basic details fields
  type: '',
  make: '',
  model: '',
  year: '',
  plateNumber: '',
  plateRegistrationType: '',
  registrationExpiration: '',
  fuelType: '',
  city: '',
  // insurance details fields
  insuranceDocument: null, 
  insuranceProviderName: '',
  policyNumber: '',
  insuranceType: '',
  insuranceExpiryDate: '',
  serviceProvider: '',
  // other details info
  number: '',
  status: true,
  tamm: true,
  petroApp: true,
  tracker: true,
  stickerCondition: true,
  mvp: '',
  contract: '',
  operationStatus: '',
  note: '',
}


const Vehicles = (props) => {
  const { users ,totalVehiclesCount,vehicleList, cities} = useSelector(state => ({
    users: state.Users.users,
    vehicleList: state.Vehicles.vehicles,
    totalVehiclesCount: state.Vehicles.totalVehicles,
    cities: state.City.cities
  }))
  const initalValueForStatusFilters = false
  const [userData, setUserData] = useState({});
  const [modal, setModal] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [form, setForm] = useState(initialState);
  const [editIndex, setEditIndex] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteModal, setDeleteModal] = useState(false);
  const [vehicleToDelete, setVehicleToDelete] = useState(null);
  const [totalVehicles, setTotalVehicles] = useState(0)
  const [statusDisabled, setStatusDisabled] = useState(initalValueForStatusFilters)
  const [statusEnabled, setStatusEnabled] = useState(initalValueForStatusFilters)
  const [isLoading, setLoading] = useState(true)
  const [isStatusFilter, setIsStatusFilter] = useState(false)
  const [insuranceDocument, setInsuranceDocument] = useState(null)
  const [contract, setContractDocument] = useState(null)
  const [istimarah, setIstimarah] = useState(null)
  const [errors, setErrors] = useState({});
  const basicDetailsRef = useRef(null);

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    )
  }
  
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    )
  }

  const hasCreatePermission = () => userData?.permissions?.allPermissions == true || userData?.permissions?.vehicle.create == true
  const hasUpdatePermission = () => userData?.permissions?.allPermissions == true || userData?.permissions?.vehicle.update == true

  // Toggle Modal
  const toggleModal = () => {
    if (modal) {
      resetForm()
      setErrors({})
    }
    setModal(!modal)

  };
  const toggleDeleteModal = (vehicle) => {
    setVehicleToDelete(vehicle); // Store the vehicle to be deleted
    setDeleteModal(!deleteModal); // Toggle the delete modal
  };

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])
  // Handle Input Change
  const handleChange = (e) => {
    let { name, value } = e.target;
    if(name == 'status' && value ==  'true'){
      value = true
    } else if(name == 'status' && value ==  'false'){
      value = false
    }
    setForm({ ...form, [name]: value });
  };

  const dispatch = useDispatch()

  const fetchVehicles = async () => {
    setLoading(true)
    try {
      dispatch(getVehicles({
        page: currentPage,
        pageSize: rowsPerPage,
        searchText,
        statusDisabled,
        statusEnabled
      }))
      dispatch(fetchCities({}))
    } catch (error) {
      toastr.error('Error fetching vehicles');
    } finally {
      setIsStatusFilter(false)
      setLoading(false)
    }
  };

  const validate = () => {
    const newErrors = {};

    // Validate basicDetails fields
    if (!form.type) newErrors.type = "Vehicle Type is required";
    if (!form.make) newErrors.make = "Make is required";
    if (!form.model) newErrors.model = "Model is required";
    if (!form.year) newErrors.year = "Year is required";
    if (!form.plateNumber) newErrors.plateNumber = "Plate Number is required";

     // check expiry dates, if they are in the past show error
    const today = new Date();
    const insuranceExpiryDate = new Date(form.insuranceExpiryDate);
    if (editIndex == null && insuranceExpiryDate < today) {
      newErrors.insuranceExpiryDate = "Insurance Expiry Date cannot be in the past";
    }

    setErrors(newErrors);

    if (newErrors.type || newErrors.make || newErrors.model || newErrors.year || newErrors.plateNumber){
      basicDetailsRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    return Object.keys(newErrors).length === 0;
  };

  // Handle Add or Edit Vehicle
  const handleSave = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      if(!validate()){
        return
      }
      const payload = {
        type: form.type,
        model: form.model,
        plateNumber: form.plateNumber,
        plateRegistrationType: form.plateRegistrationType,
        city: form.city,
        make: form.make,
        year: form.year,
        fuelType: form.fuelType,
        registrationExpiration: form.registrationExpiration,
        insuranceDocument,
        insuranceProviderName: form.insuranceProviderName,
        policyNumber: form.policyNumber,
        insuranceType: form.insuranceType,
        insuranceExpiryDate: form.insuranceExpiryDate,
        serviceProvider: form.serviceProvider,
        tamm: form.tamm,
        petroApp: form.petroApp,
        tracker: form.tracker,
        stickerCondition: form.stickerCondition,
        mvp: form.mvp,
        operationStatus: form.operationStatus,
        note: form.note,
        status: form.status,
        contract,
        istimarah
      };

      // Check if the insurance expiry date is in the past
        const today = new Date();
        const expiryDate = new Date(form.insuranceExpiryDate);

      const formData = new FormData();

      for (const key in payload) {
        if (payload[key] !== undefined && payload[key] !== null && payload[key] !== "") {
          formData.append(key, payload[key]);
        }
      }
      
      let response;
      if (editIndex !== null) {
        formData.append('vehicleId', vehicles[editIndex]._id);
        response = await post('/vehicles/update', formData, true);
      } else {
        response = await post('/vehicles/create', formData, true);
      }

      if (response?.statusCode == 200) {
        fetchVehicles();
        resetForm();
        toggleModal();
        toastr.success(response.msg);
      } else {
        toastr.error(response.msg)
      }
  
    } catch (error) {
      console.log("error", error)
      toastr.error('Error saving vehicle');
    } finally {
      setLoading(false)
    }
  };

  // Reset Form and Edit Index
  const resetForm = () => {
    setForm(initialState);
    setContractDocument(null)
    setInsuranceDocument(null)
    setIstimarah(null)
    setEditIndex(null);
  };

  // Handle Edit Button Click
  const handleEdit = (index) => {
    setForm(vehicles[index]);
    setContractDocument(vehicles[index]?.contract)
    setInsuranceDocument(vehicles[index]?.insuranceDocument)
    setIstimarah(vehicles[index]?.istimarah)
    setEditIndex(index);
    toggleModal();
  }

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page); // Update the current page state
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage); // Update the rows per page state
    setCurrentPage(page); // Optionally reset to page 1 when rows per page changes
  };


  // Columns for DataTable
  const columns = [
    {
      name: '#',
      selector: (row, index) => index + 1,
      width: '50px',
    },
    {
      name: 'Vehicle Type',
      selector: 'type',
      sortable: true,
    },
    {
      name: 'Vehicle Model',
      selector: 'model',
      sortable: true,
    },
    {
      name: 'Plate Number',
      selector: 'plateNumber',
      sortable: true,
    },
    {
      name: 'Status',
      sortable: true,
      selector: (row) => (row.status ? 'Active' : 'Inactive'),
    },
    ...(
      hasUpdatePermission() == true
        ? [
          {
            name: 'Actions',
            cell: (row, index) => (
              <>
                <Button
                  disabled={isLoading}
                  color="warning"
                  size="sm"
                  className="me-2"
                  onClick={() => handleEdit(index)}
                >
                  <i className="bx bx-edit-alt"></i>
                </Button>
              </>
            ),
          },
        ]
        : []
    ),
  ];

  const debouncedFetchVehicles = _.debounce(fetchVehicles, 800);

  useEffect(() => {
    fetchVehicles();
  }, []);

  useEffect(()=>{
    setVehicles(vehicleList)
    setTotalVehicles(totalVehiclesCount)
  },[vehicleList,totalVehiclesCount])

  // Track if we should reset pagination
  useEffect(() => {
    if (searchText || statusDisabled !== initalValueForStatusFilters || statusEnabled !== initalValueForStatusFilters) {
      setCurrentPage(1); // Reset current page
      setRowsPerPage(10); // Reset rows per page
    }
  }, [searchText, statusDisabled, statusEnabled]);

  // Fetch vehicles only after state updates (pagination reset or filter changes)
  useEffect(() => {
    debouncedFetchVehicles();

    return () => {
      debouncedFetchVehicles.cancel();
    };
  }, [currentPage, rowsPerPage, searchText, statusDisabled, statusEnabled]);

  const insuaranceInputRef = useRef(null); 
  const contractInputRef = useRef(null); 
  const istimarahInputRef = useRef(null); 

  const handleFileChange = (e) => {
    if(e?.target?.name == 'insurance') {
      setInsuranceDocument(e.target.files[0]);
    }
    if(e?.target?.name == 'contract') {
      setContractDocument(e.target.files[0]);
    }
    if(e?.target?.name == 'istimarah') {
      setIstimarah(e.target.files[0]);
    }
  };
  const triggerFileInput = (field) => {
    if(field == 'insurance') {
      insuaranceInputRef.current.click();
    } 

    if(field == 'contract') {
      contractInputRef.current.click();
    }

    if(field == 'istimarah') {
      istimarahInputRef.current.click();
    }

   
  };





  if (userData?.permissions?.allPermissions == true || userData?.permissions?.vehicle?.read == true) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Masters | Vehicle</title>
          </MetaTags>
  
          <div className="container-fluid">
            <Breadcrumbs
              title={props.t("Masters")}
              breadcrumbItem={props.t("Vehicle Master")}
            />
            {hasCreatePermission() == true && <Button disabled={isLoading} className="mb-4" color="primary" onClick={toggleModal} style={{ float: 'right' }}>
              Add New Vehicle
            </Button>}
            <div className="mb-4 d-flex align-items-center w-full justify-content-between">
  
              <div className="w-50">
                <Input
                  disabled={isLoading}
                  type="text"
                  placeholder="Search by Model or Plate Number"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  className='w-full p-2 border rounded'
                />
              </div>
              <div className='d-flex  align-items-center'>
                <div className="d-flex align-items-center">
                  <span className={`me-2 ${statusEnabled ? "text-success font-weight-semibold" : ""}`}>Show Enabled</span>
                  <div className="form-check form-switch form-switch-lg">
                    <input
                      disabled={isLoading}
                      type="checkbox"
                      className="form-check-input form-slider-color-green"
                      defaultChecked={statusEnabled}
                      onChange={(e) => { setStatusEnabled(!statusEnabled) }}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <span className={`me-2 ${statusDisabled ? "text-success font-weight-semibold" : ""}`}>Show Disabled</span>
                  <div className="form-check form-switch form-switch-lg">
                    <input
                      disabled={isLoading}
                      type="checkbox"
                      className="form-check-input form-slider-color-green"
                      defaultChecked={statusDisabled}
                      onChange={(e) => { setStatusDisabled(!statusDisabled) }}
                    />
                  </div>
                </div>
              </div>
  
  
  
            </div>
  
            <DataTable
              columns={columns}
              data={vehicles}
              pagination
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
              paginationDefaultPage={currentPage}
              onChangePage={handlePageChange}
              paginationTotalRows={totalVehicles}
              onChangeRowsPerPage={handleRowsPerPageChange}
              progressPending={isLoading}
              progressComponent={<Skeleton height={50} count={5} />}
              paginationServer
            />
  
  
          
            {/* Modal for Add/Edit Vehicle */}
                      <Modal size="lg" isOpen={modal} toggle={toggleModal}>
                      <ModalHeader toggle={toggleModal}><h4>{editIndex  ? "Edit" : "Add"} Vehicle</h4> </ModalHeader>
                      <ModalBody>
                      <Form>
                      
                      <h5 className="mt-1 text-primary"  ref={basicDetailsRef}>Basic Details</h5>
                      <hr />
                        <div className="row">
                        <div className="col-md-4">
                        <FormGroup className="mb-3">
                        <Label for="vehicleType">Vehicle Type <span className="mandatory_feild">*</span></Label>
                        <Input
                          disabled={isLoading}
                          type="select"
                          name="type"
                          id="vehicleType"
                          value={form.type}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Choose an Option</option>
                          <option value="Car">Car</option>
                          <option value="Van">Van</option>
                        </Input>
                        {errors.type && <FormText color="danger">{errors.type}</FormText>}
                        </FormGroup>
                        </div>
                        <div className="col-md-4">
                        <FormGroup className="mb-3">
                        <Label for="make">Make <span className="mandatory_feild">*</span></Label>
                        <Input
                          disabled={isLoading}
                          type="text"
                          name="make"
                          id="make"
                          placeholder="Enter make"
                          value={form.make}
                          onChange={handleChange}
                        />
                        {errors.make && <FormText color="danger">{errors.make}</FormText>}
                        </FormGroup>

                        </div>
                      
                       
                        <div className="col-md-4">
                        <FormGroup className="mb-3">
                          <Label for="model">Model <span className="mandatory_feild">*</span></Label>
                          <Input
                          disabled={isLoading}
                          type="text"
                          name="model"
                          id="model"
                          placeholder="Enter model"
                          value={form.model}
                          onChange={handleChange}
                          />
                          {errors.model && <FormText color="danger">{errors.model}</FormText>}
                        </FormGroup>
                        </div>
                        <div className="col-md-4">
                        <FormGroup className="mb-3">
                        <Label for="year">Model Year <span className="mandatory_feild">*</span></Label>
                        <Input
                          disabled={isLoading}
                          type="text"
                          name="year"
                          id="year"
                          placeholder="Enter year"
                          value={form.year}
                          onChange={handleChange}
                        />
                        {errors.year && <FormText color="danger">{errors.year}</FormText>}
                        </FormGroup>
                        
                        </div>
                        
                       
                        <div className="col-md-4">
                        <FormGroup className="mb-3">
                        <Label for="plateNumber">Plate Number <span className="mandatory_feild">*</span></Label>
                        <Input
                          disabled={isLoading}
                          type="text"
                          name="plateNumber"
                          id="plateNumber"
                          placeholder="Enter plate number"
                          value={form.plateNumber}
                          onChange={handleChange}
                        />
                        {errors.plateNumber && <FormText color="danger">{errors.plateNumber}</FormText>}
                        </FormGroup>
                        
                        </div>
                        <div className="col-md-4">
                        <FormGroup className="mb-3">
                        <Label for="plateRegistrationType">Plate Registration Type</Label>
                        <Input
                          disabled={isLoading}
                          type="select"
                          name="plateRegistrationType"
                          id="plateRegistrationType"
                          value={form.plateRegistrationType}
                          onChange={handleChange}
                        >
                          <option value="">Choose an Option</option>
                          <option value="private_transport">Private Transport</option>
                          <option value="public_transport">Public Transport</option>
                          <option value="private_car">Private Car</option>
                        </Input>
                        </FormGroup>
                        </div>
                      
                        
                        <div className="col-md-4">
                        <FormGroup className="mb-3">
                        <Label for="registrationExpiration">Registration Expiration</Label>
                        <Input
                          disabled={isLoading}
                          type="date"
                          name="registrationExpiration"
                          id="registrationExpiration"
                          value={formatDate(form.registrationExpiration)}
                          onChange={handleChange}
                        />
                        </FormGroup>
                        </div>
                        <div className="col-md-4">
                        <FormGroup className="mb-3">
                        <Label for="fuelType">Fuel Type</Label>
                        <Input
                          disabled={isLoading}
                          type="select"
                          name="fuelType"
                          id="fuelType"
                          value={form.fuelType}
                          onChange={handleChange}
                        >
                          <option value="">Choose an Option</option>
                          <option value="petrol">Petrol</option>
                          <option value="diesel">Diesel</option>
                        </Input>
                        </FormGroup>
                        </div>
        
                        <div className="col-md-4">
                        
                        <FormGroup className="mb-3">
                        <Label for="city">City</Label>
                        <Input
                          type="select"
                          name="city"
                          id="city"
                          value={form.city}
                          onChange={handleChange}
                          >
                            <option value="">Choose a City</option>
                            {cities.map(city => (
                              <option key={city._id} value={city._id}>{city.name}</option>
                            ))}
                         
                        </Input>
                        </FormGroup>
                        </div>
                        </div>

                        
                        <h5 className="mt-4 text-primary">Insurance</h5>
                        <hr />
                        <div className="row">
                    <div className="col-md-4">

                      <Label>Insurance Document</Label>
                      <div className="mb-3 d-flex flex-column">
                        <Button color="primary" outline style={{ marginRight: '5px' }} onClick={() => triggerFileInput('insurance')}>
                          {insuranceDocument?.name || insuranceDocument?.split('/').pop() ? 'Change' : <><i className="fa fa-upload h-4 w-4 px-1 mr-1"></i>Upload</>}
                        </Button>
                        <input
                          name="insurance"
                          type="file"
                          ref={insuaranceInputRef}
                          accept="image/*,application/pdf"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                         {
                            typeof insuranceDocument === 'string' ? (
                              <a target='_blank' rel='noreferrer' href={insuranceDocument} className='mt-2'>
                                View uploaded document
                              </a>
                            ) : (
                              insuranceDocument?.name && (
                                <span className="mt-2">{insuranceDocument.name}</span>
                              )
                            )
                          }
                      </div>
                    </div>
                    <div className="col-md-4">
                        <FormGroup className="mb-3">
                        <Label for="insuranceProviderName">Insurance Provider Name</Label>
                        <Input
                          disabled={isLoading}
                          type="text"
                          name="insuranceProviderName"
                          id="insuranceProviderName"
                          placeholder="Enter insurance provider name"
                          value={form.insuranceProviderName}
                          onChange={handleChange}
                        />
                        </FormGroup>
                        </div>
                        
                      <div className="col-md-4">
                        <FormGroup className="mb-3">
                        <Label for="policyNumber">Policy Number</Label>
                        <Input
                          disabled={isLoading}
                          type="text"
                          name="policyNumber"
                          id="policyNumber"
                          placeholder="Enter policy number"
                          value={form.policyNumber}
                          onChange={handleChange}
                        />
                        </FormGroup>

                      </div>

                      <div className="col-md-4">
                        <FormGroup className="mb-3">
                        <Label for="insuranceType">Insurance Type</Label>
                        <Input
                          disabled={isLoading}
                          type="select"
                          name="insuranceType"
                          id="insuranceType"
                          value={form.insuranceType}
                          onChange={handleChange}
                        >
                          <option value="">Choose an Option</option>
                          <option value="comprehensive">Comprehensive</option>
                          <option value="third_party">Third Party</option>
                        </Input>
                        </FormGroup>
                        </div>
                        <div className="col-md-4">
                        <FormGroup className="mb-3">
                        <Label for="insuranceExpiryDate">Insurance Expiry Date</Label>
                        <Input
                          disabled={isLoading}
                          type="date"
                          name="insuranceExpiryDate"
                          id="insuranceExpiryDate"
                          value={formatDate(form.insuranceExpiryDate)}
                          onChange={handleChange}
                          min={new Date().toISOString().split('T')[0]}
                        />
                        {
                          errors.insuranceExpiryDate && <FormText color="danger">{errors.insuranceExpiryDate}</FormText>
                        }
                        </FormGroup>
                      </div>
                        </div>
                     

                      
                      <h5 className="mt-4 text-primary">Other Info</h5>
                      <hr />

                      <div className="row">
                      
                      <div className="col-md-4">

                        <FormGroup className="mb-3">
                        <Label for="tamm" className='me-2'>Tamm</Label>
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                          onChange={(e) => setForm({ ...form, tamm: !form.tamm })}
                          checked={form.tamm}
                        />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup className="mb-3">
                        <Label for="petroApp" className='me-2'>Petro App</Label>
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                          onChange={(e) => setForm({ ...form, petroApp: !form.petroApp })}
                          checked={form.petroApp}
                        />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup className="mb-3">
                        <Label for="tracker" className='me-2'>Tracker</Label>
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                          onChange={(e) => setForm({ ...form, tracker: !form.tracker})}
                          checked={form.tracker}
                        />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">

                        
                        <FormGroup className="mb-3">
                        <Label for="stickerCondition" className='me-2'>Sticker Condition</Label>
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                          onChange={(e) => setForm({ ...form, stickerCondition: !form.stickerCondition})}
                          checked={form.stickerCondition}
                        />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">

                    <Label for="istimarah">Istimarah</Label>
                      <div className="mb-3 d-flex flex-column">
                        <Button color="primary" outline style={{ marginRight: '5px' }} onClick={() => triggerFileInput('istimarah')}>
                          {istimarah?.name || istimarah?.split('/').pop() ? 'Change' : <><i className="fa fa-upload h-4 w-4 px-1 mr-1"></i>Upload</>}
                        </Button>
                        <input
                          name="istimarah"
                          type="file"
                          ref={istimarahInputRef}
                          accept="image/*,application/pdf"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                        {
                            typeof istimarah === 'string' ? (
                              <a target='_blank' rel='noreferrer' href={istimarah} className='mt-2'>
                                View uploaded document
                              </a>
                            ) : (
                              istimarah?.name && (
                                <span className="mt-2">{istimarah.name}</span>
                              )
                            )
                          }
                      </div>
                    </div>
                      <div className="col-md-4">
                        <FormGroup className="mb-3">
                        <Label for="mvp">MVP</Label>
                        <Input
                          disabled={isLoading}
                          type="text"
                          name="mvp"
                          id="mvp"
                          placeholder="Enter MVP"
                          value={form.mvp}
                          onChange={handleChange}
                        />
                        </FormGroup>
                      </div>
                    <div className="col-md-4">

                    <Label for="contract">Contract</Label>
                      <div className="mb-3 d-flex flex-column">
                        <Button color="primary" outline style={{ marginRight: '5px' }} onClick={() => triggerFileInput('contract')}>
                          {contract?.name || contract?.split('/').pop() ? 'Change' : <><i className="fa fa-upload h-4 w-4 px-1 mr-1"></i>Upload</>}
                        </Button>
                        <input
                          name="contract"
                          type="file"
                          ref={contractInputRef}
                          accept="image/*,application/pdf"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                        {
                            typeof contract === 'string' ? (
                              <a target='_blank' rel='noreferrer' href={contract} className='mt-2'>
                                View uploaded document
                              </a>
                            ) : (
                              contract?.name && (
                                <span className="mt-2">{contract.name}</span>
                              )
                            )
                          }
                      </div>
                    </div>
                      <div className="col-md-4">
                        <FormGroup className="mb-3">
                        <Label for="operationStatus">Operation Status</Label>
                        <Input
                          disabled={isLoading}
                          type="select"
                          name="operationStatus"
                          id="operationStatus"
                          value={form.operationStatus}
                          onChange={handleChange}
                        >
                          <option value="">Choose an Option</option>
                          <option value="operational">Operational</option>
                          <option value="workshop">Workshop</option>
                          <option value="total_loss">Total Loss</option>
                          <option value="replacement">Replacement</option>
                        </Input>
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                      <FormGroup className="mb-3">
                        <Label for="serviceProvider">Service Provider</Label>
                        <Input
                          disabled={isLoading}
                          type="text"
                          name="serviceProvider"
                          id="serviceProvider"
                          placeholder="Enter service provider"
                          value={form.serviceProvider}
                          onChange={handleChange}
                        />
                        </FormGroup>
                      </div>
                      </div>
                      <div className="row">
                      <div className="col-md-4">
                        <FormGroup className="mb-3">
                        <Label for="note">Note</Label>
                        <Input
                          disabled={isLoading}
                          type="textarea"
                          name="note"
                          id="note"
                          placeholder="Enter note"
                          value={form.note}
                          onChange={handleChange}
                        />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup className="mb-3 mt-4">
                        <Label for="status" className='me-2'>Status</Label>
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                          onChange={(e) => setForm({ ...form, status: !form.status })}
                          checked={form.status}
                        />
                        </FormGroup>
                      </div>
                      </div>
                      
                      
                    </Form>
                    
                    </ModalBody>
                    <ModalFooter>
                    <Button disabled={isLoading} color="secondary" onClick={toggleModal}>Cancel</Button>
                    <Button disabled={isLoading} color="primary" onClick={handleSave}>Save</Button>
                    </ModalFooter>
                  </Modal>
          </div>
        </div>
      </React.Fragment >
    )
  } else {
    return <PagesNoPermission />
  }
};

Vehicles.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Vehicles);

function formatDate(dateString) {
  const date = new Date(dateString);
  
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const day = String(date.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
}