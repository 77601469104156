import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import DataTable from "react-data-table-component"

import { Button, Card, CardBody, Container, Row } from "reactstrap"
import {
  searchUsers,
  getTimeSlots,
  createTimeSlot,
  updateTimeSlot,
} from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import Breadcrumbs from "components/Common/Breadcrumb"

import Pages404 from "pages/Utility/pages-404"
import EditTimeSlotModal from "./modals/EditTimeSlotModal"
import Select from "react-select"
import Switch from "react-switch"
import { postArea } from "helpers/LaundryHelper"
import ReactSwitch from "react-switch"
import { apiSuccess } from "store/timeslots/actions"
import { LIST_TIMESLOTS } from "store/timeslots/actionTypes"
import { postWeb } from "helpers/api_helper"
import { UPDATE_TIMESLOT, CREATE_TIMESLOT } from "helpers/url_helper"
import toastr from "toastr";

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Off
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      On
    </div>
  )
}

const TimeSlots = props => {
  const dispatch = useDispatch()

  const [userData, setUserData] = useState({})
  const [isEditTimeSlotModalOpen, setIsEditTimeSlotModalOpen] = useState(false)
  const [selectedTimeSlot, setSelectedTimeSlot] = useState()

  const { timeslots, users } = useSelector(state => ({
    timeslots: state.Timeslots.timeslots,
    users: state.Users.users,
  }))

  const [selectedArea, setSelectedArea] = React.useState()
  const [areas, setAreas] = React.useState([])

  React.useEffect(async () => {
    const AreaResponse = await postArea({})
    setAreas(
      AreaResponse?.record?.map(item => {
        return { label: item?.name, value: item?._id, ...item }
      })
    )
  }, [])

  useEffect(() => {
    if(!users) dispatch(searchUsers({ webPermissions: true }))
  }, [])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0])
    }
  }, [users])

  useEffect(() => {
    console.log(timeslots)
  }, [timeslots])

  const onSaveTimeSlot = (values, timeSlotId) => {
    if (timeSlotId) {
      postWeb(`${UPDATE_TIMESLOT}`, { _id: timeSlotId, ...values }).then((response) => {
        
        if(response.statusCode == 200){
          loadTimeslots();
          toastr.success("Timeslot updated successfully")
          hideModal();
        }
        else {
          if(response.msg){
            toastr.error(response.msg)
          }
          else{
            toastr.error("Error is saving timeslot")
          }
          
        }

      })
      
    } else {
      postWeb(`${CREATE_TIMESLOT}`, values).then((response) => {
        
        if(response.statusCode == 200){
          loadTimeslots();
          toastr.success("Timeslot Created successfully")
          hideModal();
          
        }
        else {
          if(response.msg){
            toastr.error(response.msg)
          }
          else{
            toastr.error("Error is creating timeslot")
          } 
        }
      })
    }
  }

  const timeSlotColumns = [
    {
      name: "Timeslot",
      compact: true,
      selector: row => {
        return `${row.start} - ${row.end}`
      },
    },
    {
      name: "Closing",
      compact: true,
      selector: row => row.closingTime,
    },
    {
      name: "Areas",
      grow: 3,
      center: true,
      compact: true,
      wrap: true,
      selector: row => (
        <div>
          {row?.areas.map((area, index) => {
            const isInitialIndex = index === 0
            const name = area?.name
            return name ? `${isInitialIndex ? "" : " | "}${name}` : ""
          })}
        </div>
      ),
    },
    {
      name: "Status",
      compact: true,
      selector: row => {
        return userData?.permissions?.allPermissions == true ||
          userData?.permissions?.timeSlots?.update == true ? (
          <Switch
            uncheckedIcon={<Offsymbol />}
            checkedIcon={<OnSymbol />}
            onColor="#626ed4"
            onChange={val => {
              dispatch(updateTimeSlot({ _id: row._id, status: val }))
            }}
            checked={row.status}
          />
        ) : row.status ? (
          "On"
        ) : (
          "Off"
        )
      },
    },
    {
      name: "Action",
      omit: !(
        userData?.permissions?.allPermissions == true ||
        userData?.permissions?.timeSlots?.update == true
      ),
      compact: true,
      center: true,
      selector: row => {
        return (
          <Button
            color="primary"
            onClick={() => {
              setIsEditTimeSlotModalOpen(true)
              setSelectedTimeSlot(row)
            }}
          >
            View
          </Button>
        )
      },
    },
  ]

  const hideModal = () => {
    setIsEditTimeSlotModalOpen(false)
    setSelectedTimeSlot()
  }

  const [fetchStatusTrue, setFetchStatusTrue] = useState(true)
  const [fetchStatusFalse, setFetchStatusFalse] = useState(true)

  const loadTimeslots = () => {
    let requestBody = {}
    if (fetchStatusTrue && fetchStatusFalse) {
      requestBody.status = { $in: [true, false] }
    } else if (fetchStatusTrue && !fetchStatusFalse) {
      requestBody.status = true
    } else if (fetchStatusFalse && !fetchStatusTrue) {
      requestBody.status = false
    } else if (!fetchStatusFalse && !fetchStatusTrue) {
      dispatch(
        apiSuccess(LIST_TIMESLOTS, {
          record: [],
        })
      )
      return
    }
    dispatch(getTimeSlots(requestBody))
  }
  
  useEffect(() => {
    loadTimeslots();
  }, [fetchStatusTrue, fetchStatusFalse])

  if (
    userData?.permissions?.allPermissions == true ||
    userData?.permissions?.timeSlots?.read == true
  ) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Time Slots</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Masters" breadcrumbItem="Time Slots" />
            <Card>
              <CardBody>
                <Row className="mb-2 justify-content-between">
                  <div style={{ width: "20rem" }}>
                    <Select
                      placeholder="All Areas"
                      onChange={val => {
                        setSelectedArea(val)
                      }}
                      isClearable={true}
                      options={areas}
                    />
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ flexBasis: "content" }}
                  >
                    <div className="d-flex align-items-center mx-3">
                      <span
                        className={`me-2 ${
                          fetchStatusTrue
                            ? "text-success font-weight-semibold"
                            : ""
                        }`}
                      >
                        Show Enabled
                      </span>
                      <div className="form-check form-switch form-switch-lg">
                        <input
                          type="checkbox"
                          className="form-check-input form-slider-color-green"
                          defaultChecked={fetchStatusTrue}
                          onChange={e => {
                            setFetchStatusTrue(!fetchStatusTrue)
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center me-5">
                      <span
                        className={`me-2 ${
                          fetchStatusFalse
                            ? "text-success font-weight-semibold"
                            : ""
                        }`}
                      >
                        Show Disabled
                      </span>
                      <div className="form-check form-switch form-switch-lg">
                        <input
                          type="checkbox"
                          className="form-check-input form-slider-color-green"
                          defaultChecked={fetchStatusTrue}
                          onChange={e => {
                            setFetchStatusFalse(!fetchStatusFalse)
                          }}
                        />
                      </div>
                    </div>
                    {userData?.permissions?.allPermissions == true ||
                    userData?.permissions?.timeSlots?.create == true ? (
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded mb-2 me-2"
                        style={{
                          width: "10rem",
                        }}
                        onClick={() => setIsEditTimeSlotModalOpen(true)}
                      >
                        <i className="mdi mdi-plus me-1" />
                        Add Time Slot
                      </Button>
                    ) : null}
                  </div>
                </Row>
                <Row>
                  <DataTable
                    columns={timeSlotColumns}
                    data={timeslots.filter(slot => {
                      if (!selectedArea) {
                        return true
                      }
                      return slot.areas
                        .map(area => area._id)
                        .includes(selectedArea?.value)
                    })}
                    pagination
                  />
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>

        <EditTimeSlotModal
          isOpen={isEditTimeSlotModalOpen}
          toggle={hideModal}
          timeSlot={selectedTimeSlot}
          onAction={onSaveTimeSlot}
        />
      </React.Fragment>
    )
  } else {
    return <Pages404 />
  }
}

TimeSlots.propTypes = {}

export default withRouter(TimeSlots)
