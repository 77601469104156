import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Switch from "react-switch"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Input,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { AvForm, AvField, Lable } from "availity-reactstrap-validation";
import { getLaundry, fetchLaundryList, postAddLaundry, postUpdateLaundry, postArea } from "helpers/LaundryHelper"
import { postAddUser } from "helpers/userHelper";
import { getLaundries, searchUsers, toggleLaundryActive, addNewDriver, updateDrivers, deleteDrivers, updateClothes, addNewClothes } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
  getClothesList,
  getArealist,
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "store/actions";
import Pages404 from "pages/Utility/pages-404";

// import EcommerceOrdersModal from "./EcommerceOrdersModal";
const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Off
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      On
    </div>
  )
}

const Clothes = props => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [clothNameEn, setClothNameEn] = useState("");
  const [clothNameAr, setClothNameAr] = useState("");
  const [unit, setUnit] = useState("");
  const [category, setCategory] = useState("");
  const [areaList, setAreaList] = useState([]);
  const [areaOption, setAreaOption] = useState([]);
  const [value1, setvalue] = useState([]);
  const [selectedAreasList, setSelectedAreasList] = useState([])
  const [availability, setAvailability] = useState([])
  const [onlineVal, setOnlineVal] = useState(true)
  const [statusVal, setStatusVal] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [editableItem, setEditableItem] = useState({})
  const [error, setError] = useState({})
  const [userData, setUserData] = useState({});
  const [clothesDataList, setClothesDataList] = useState([]);
  const [image, setImage] = useState("");
  const [searchText, setSearchText] = useState("");


  const { laundryList, clothes, users } = useSelector(state => ({
    users: state.Users.users,
    clothes: state.Clothes.clothesList,
    laundryList: state.Laundries.laundryList
  }));

  const Weeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  useEffect(() => {
    if (!modal) {
      setStatusVal(true)
      
      setAvailability([])
      setSelectedAreasList([])
      setvalue([])
      setClothNameEn("")
      setClothNameAr("")
      setUnit("")
      setCategory("")
      setIsEdit(false)
      setEditableItem({})
    }
  }, [modal])

  useEffect(() => {
    if(searchText != ""){
      let filterBySearch = filterClothes(searchText);
      setClothesDataList(filterBySearch)
    }
    else{
      setClothesDataList(clothes)  
    }
  }, [clothes])

  const filterClothes = (q) => {  
    return clothes.filter((item) => {
      const unit = item?.type == "m"?"meters":"pc";
      return item?.nameEn?.toLowerCase()?.includes(q) 
        || unit.includes(q)
        || item?.nameAr?.toLowerCase()?.includes(q)
    })
  }
  
  const handleSearch = (val) => {
    val = val?.toLowerCase()
    setSearchText(val);
    let filterBySearch = filterClothes(val);
    if (!val) {
      setClothesDataList(clothes)
    } else {
      setClothesDataList(filterBySearch)
    }
    // console.log("filterBySearch", filterBySearch);
  }

  const handleOnchange = (val) => {
    setvalue(val ? val.split(",") : [])
  }
  useEffect(() => {
    let areaListFilter = areaList.filter((item) => value1.includes(item._id))
    setSelectedAreasList(areaListFilter);
    let newArrayAvalibility = []
    areaListFilter.map((item) => {
      let oldAvailability = [...availability]
      let oldAvailabilityIndex = oldAvailability.findIndex((data) => data.area === item._id);
      if (oldAvailabilityIndex !== -1) {
        newArrayAvalibility.push(oldAvailability[oldAvailabilityIndex]);
      } else {
        newArrayAvalibility.push({ area: item._id, days: [0, 1, 2, 3, 4, 5, 6] });
      }
    })
    setAvailability(newArrayAvalibility)
  }, [value1])

  const handleAvailability = (e, areaID) => {
    let selectedAvailibility = [...availability]
    let findAvailabilityData = selectedAvailibility.findIndex((item) => item.area == areaID)
    if (findAvailabilityData !== -1) {
      let selectedDays = selectedAvailibility[findAvailabilityData]?.days ? selectedAvailibility[findAvailabilityData]?.days : [];
      if (selectedDays.includes(parseInt(e.target.value))) {
        selectedDays.splice(selectedDays.indexOf(parseInt(e.target.value)), 1)
      } else {
        selectedDays.push(parseInt(e.target.value))
      }
      selectedAvailibility[findAvailabilityData].days = selectedDays;
    } else {
      selectedAvailibility.push({ area: areaID, days: [parseInt(e.target.value)] })
    }
    setAvailability(selectedAvailibility)
  }

  useEffect(() => {
    const data = {}
    dispatch(getClothesList(data))
  }, [])

  useEffect(() => {
    const data = {}
    if(!users) dispatch(searchUsers({ webPermissions: true }))
    dispatch(getLaundries(data))
  }, [])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])


  useEffect(async () => {
    const AreaResponse = await postArea({});
    setAreaList(AreaResponse?.record);
    setAreaOption(
      AreaResponse?.record?.map((item) => {
        return { label: item?.name, value: item?._id }
      })
    )
  }, [])

  useEffect(() => {
    // console.log("drivers", drivers);
    // console.log("areaList", areaList);
    // console.log("laundryList", laundryList);
  }, [laundryList])

  const selectRow = {
    mode: "checkbox",
  };

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: clothesDataList?.length, // replace later with size(orders),
    custom: true,
  };
  

  const handleEditCloth = (item) => {
    setEditableItem(item)
    setIsEdit(true);
    setClothNameEn(item.nameEn)
    setClothNameAr(item.nameAr)
    setUnit(item.type)
    setCategory(item?.category ? item.category : "")
    setModal(true)
  }

  useEffect(() => {
    if (!modal) {
      setError({})
    }
  }, [modal])

  let ClothesColumns = [
    {
      name: "Name (English)",
      sortable: true,
      selector: row => row.nameEn,
      wrap: true,
    },
    {
      name: "Name (Arabic)",
      sortable: true,
      selector: row => row.nameAr,
      wrap: true,
    },
    {
      name: "Image",
      selector: row => {
        return <>
         {row?.image?<img src={row?.image} width={30} height={30} />: null}
        </>
      },
      wrap: true,
    }, {
      name: "Unit",
      sortable: true,
      wrap: true,
      center: true,
      selector: row => {
        return <>
         {row?.type?(row?.type=="pc"?'PC':'Meters'):null}
        </>
      },
    }, {
      name: "Category",
      sortable: true,
      wrap: true,
      center: true,
      selector: row => {
        return <>
         {row?.category?(row?.category=="clothes"?'Clothes':'Carpet'):null}
        </>
      },
    }
  ];

  if (userData?.permissions?.allPermissions == true || userData?.permissions?.clothes?.update == true) {
    ClothesColumns.push({
      name: "View",
      center: true,
      selector: row => {
        return <>
          <button className="btn btn-primary btn-sm" onClick={() => handleEditCloth(row)}>
            View
          </button>
          {/* <a onClick={() => showEditLaundry(row._id)} >Edit</a>
             &nbsp; &nbsp;
             <a onClick={() => handleDeleteLaundry(row._id)} >Delete</a> */}
          {/* <a href="#" onClick={() => showPerms(row._id)} >Permissions</a> */}
        </>
      }
    })
  }

  const toggle = () => {
    setModal(!modal);
  };
  const submitNewCloth = () => {

    if(image != undefined && (image.size/1024) > 128){
      console.log("image.size", image.size);
      setError({
        image : "File size should be less than 128KB"
      });
      return;
    }
    const formData = new FormData();
    formData.append("nameEn", clothNameEn);
    formData.append("nameAr", clothNameAr);
    formData.append("type", unit);
    formData.append("category", category);
    if(image){
      formData.append("image", image);
    }

    if (isEdit) {
      formData.append("_id", editableItem._id);
      dispatch(updateClothes(formData))
    } else {
      dispatch(addNewClothes(formData))
    }
    toggle()
  }

  var node = useRef();
  const handleOrderClicks = () => {
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "orderID",
      order: "desc",
    },
  ];

  if (userData?.permissions?.allPermissions == true || userData?.permissions?.clothes?.read == true) {
    return (
      <React.Fragment>
        {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
        <div className="page-content">
          <MetaTags>
            <title>Clothes</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Clothes" />
            <Card>
              <CardBody>
                <Row className="mb-2">
                  <Col sm="4">
                    <div className="me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <input
                          id="search-bar-10"
                          type="text"
                          aria-labelledby="search-bar-10-label"
                          className="form-control "
                          placeholder="Search"
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col sm="8">
                    <div className="text-sm-end">
                      {(userData?.permissions?.allPermissions == true || userData?.permissions?.clothes?.create == true) ?
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded  mb-2 me-2"
                          onClick={handleOrderClicks}
                        >
                          <i className="mdi mdi-plus me-1" />
                          Add Cloth
                        </Button> : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <DataTable
                    columns={ClothesColumns}
                    data={clothesDataList}
                    pagination
                  />
                </Row>
              </CardBody>
            </Card>
            <Modal isOpen={modal} toggle={toggle} size="md">
              <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? "Edit Cloth" : "Add Cloth"}
              </ModalHeader>
              <ModalBody>
                <AvForm
                  onValidSubmit={submitNewCloth}
                  encType="multipart/form-data"
                >
                  <Row form>
                    <div className="row mb-2">
                      <Col className="col-12">
                       

                        <div className="mb-3">
                          <AvField
                            name="nameEn"
                            label={<div>Name (English) <span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Name in English Required"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={(e) => { setClothNameEn(e.target.value) }}
                            value={clothNameEn}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="nameAr"
                            label={<div>Name (Arabic) <span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Name in Arabic Required"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={(e) => { setClothNameAr(e.target.value) }}
                            value={clothNameAr}
                          />
                        </div>
                        
                        <div className="mb-3">
                          <label>Unit <span className="mandatory_feild">*</span></label>
                          <AvField 
                            type="select"
                            name="unit"
                            value={unit}
                            errorMessage="Select Unit"
                            className="form-control"
                            onChange={(e) =>setUnit(e.target.value)}
                            validate={{ required: { value: true } }}
                            helpMessage="">
                                      <option value="">Selet Unit</option>
                                      <option value="pc" selected>PC</option>
                                      <option value="m">Meters</option>

                            </AvField>
                        </div>
                        <div className="mb-3">
                          <label>Category <span className="mandatory_feild">*</span></label>
                          <AvField 
                            type="select"
                            name="category"
                            value={category}
                            errorMessage="Select Category"
                            className="form-control"
                            onChange={(e) => setCategory(e.target.value)}
                            validate={{ required: { value: true } }}
                            helpMessage="">
                                      <option value="">Selet Category</option>
                                      <option value="clothes" selected>Clothes</option>
                                      <option value="carpet">Carpet</option>

                            </AvField>
                        </div>

                        <div className="mb-3">
                          <label>Image </label>
                          <AvField
                              type="file"
                              onChange={(e) => { setImage(e.target.files[0]) }}
                              className="form-control"
                              id="horizontal-firstname-Input"
                              placeholder="Image"
                              name="image"
                              errorMessage="Please Upload Image"
                              validate={false}
                            />
                            {
                              error?.image? (
                                <label style={{ color: "red" }}>{error?.image}</label>
                              ) : null
                            }
                        </div>
                        
                        {editableItem?.image != undefined?
                        <div className="mb-3">
                          <img src={editableItem.image} height="100" width="100" />
                        </div>: null}

                       
                      </Col>
                      {/* <Col className="col-6 d-flex mt-4" style={{ height: "fit-content" }}>
                        <div className="d-flex mx-5">
                          <label
                            htmlFor="horizontal-status-Input1"
                            className="status-label mt-2"
                          >Status
                          </label>
                          <div className="form-switch">
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              className="me-1 mb-sm-8 mb-2"
                              onColor="#626ed4"
                              onChange={(e) => setStatusVal(!statusVal)}
                              checked={statusVal}
                            />

                          
                          </div>
                        </div>
                        
                      </Col> */}
                    </div>
                    <div className="row mb-2">
                      
                    </div>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>


          </Container>
        </div>
      </React.Fragment>
    );
  }
  else {
    return <Pages404 />
  }
};

Clothes.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
};

export default withRouter(Clothes);
