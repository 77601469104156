import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import DataTable from "react-data-table-component";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Switch from "react-switch"
import {validateEmail, validatePassword} from "../../helpers/validationHelper";
import ReactSwitch from "react-switch"
import Select from "react-select"
import { Offsymbol, OnSymbol } from "components/Common/Symbol"


import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Input,
  Badge,
  UncontrolledTooltip,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Form,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import classnames from "classnames"
import { AvForm, AvField, Lable } from "availity-reactstrap-validation";
import { getLaundry, fetchLaundryList, postAddLaundry, postUpdateLaundry, postArea } from "helpers/LaundryHelper"
import { postAddUser, postUpdateUser, updateCredentials } from "helpers/userHelper";
import { getLaundries, searchUsers, 
  toggleLaundryActive, addNewDriver,
  deleteDrivers, filterUsers } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
  getDriverList,
  getArealist,
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "store/actions";
import Pages404 from "pages/Utility/pages-404";
import { addDrivers, updateDrivers } from "helpers/fakebackend_helper";
import { postWeb } from "helpers/api_helper";

const phCode = process.env.REACT_APP_PHONE_PREFIX || 966;

const SectionTitle = ({ title }) => (
  <>
    <h5 className="mt-4 text-primary">{title}</h5>
    <hr />
  </>
)

SectionTitle.propTypes = {
  title: PropTypes.string,
}



const EcommerceOrders = props => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [driverName, setDriverName] = useState("");
  const [mobile, setMobile] = useState("");
  const [id, setId] = useState("");
  const [idExpDate, setIdExpDate] = useState("");
  const [license, setLicense] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [licenseExpDate, setLicenseExpDate] = useState("");
  const [licenseType, setLicenseType] = useState("");
  const [employementType, setEmployementType] = useState("");
  const [salary, setSalary] = useState(0);
  const [hiringMethod, setHiringMethod] = useState("");
  const [contractDocument, setContractDocument] = useState("");
  const [authorisationExpDate, setAuthorisationExpDate] = useState("");
  const [note, setNote] = useState("");
  const [areaList, setAreaList] = useState([]);
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();

  const [areaOption, setAreaOption] = useState([]);
  const [value1, setvalue] = useState([]);
  const [selectedAreasList, setSelectedAreasList] = useState([])
  const [availability, setAvailability] = useState([])
  const [onlineVal, setOnlineVal] = useState(true)
  const [statusVal, setStatusVal] = useState(true)
  const [isFaceIdRequired, setIsFaceIdRequired] = useState(false)
  const [isFaceIdEnabled, setIsFaceIdEnabled] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editableItem, setEditableItem] = useState({})
  const [error, setError] = useState({})
  const [userData, setUserData] = useState({});
  const [driverDataList, setDriverDataList] = useState([]);
  const [pickup, setPickup] = useState(true);
  const [delivery, setDelivery] = useState(true);
  const [activeTab, setactiveTab] = useState("1");
  const [captainUser, setCaptainUser] = useState({})
  const [searchText, setSearchText] = useState("")
  const [updatedVehicle, setUpdatedVehicle] = useState("")
  const [healthCertificate, setHealthCertificate] = useState(null)
  const [certificateNumber, setCertificateNumber] = useState("");
  const [certificateIssueDate, setCertificateIssueDate] = useState("");
  const [certificateExpirationDate, setCertificateExpirationDate] = useState("");
  const basicInfoRef = useRef(null);
  const employementTypeRef = useRef(null);

  const { drivers, users, filteredUsers } = useSelector(state => ({
    users: state.Users.users,
    drivers: state.Drivers.driverList,
    filteredUsers: state.Users.filteredUsers
  }));

  const [fetchStatusTrue, setFetchStatusTrue] = useState(true)
  const [fetchStatusFalse, setFetchStatusFalse] = useState(false)

  const filterItem = (item) => {
    if(fetchStatusTrue && fetchStatusFalse){
      return true
    } else if(fetchStatusTrue && !fetchStatusFalse){
      return item.status
    } else if(fetchStatusFalse && !fetchStatusTrue){
      return !item.status
    } else {
      return false
    }
  }

  const [selectedArea, setSelectedArea] = React.useState()
  const [areas, setAreas] = React.useState([])

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Off
      </div>
    )
  }
  
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        On
      </div>
    )
  }
  
  const OffNoText = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
      </div>
    )
  }
  
  const OnNoText = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        
      </div>
    )
  }
  

  React.useEffect(async () => {
    const AreaResponse = await postArea({})
    setAreas(
      AreaResponse?.record?.map(item => {
        return { label: item?.name, value: item?._id, ...item }
      })
    )
  }, [])

  const Weeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  useEffect(() => {
    if (!modal) {
      setStatusVal(true)
      setOnlineVal(true)
      setIsFaceIdRequired(true)
      setIsFaceIdEnabled(false)
      setAvailability([])
      setSelectedAreasList([])
      setvalue([])
      setDriverName("")
      setMobile("")
      setId("")
      setIdExpDate("")
      setLicense("")
      setLicenseExpDate("")
      setLicenseType("")
      setLicenseNumber('')
      setHealthCertificate('')
      setCertificateNumber('')
      setCertificateIssueDate('')
      setCertificateExpirationDate('')
      setEmployementType('')
      setSalary("")
      setHiringMethod("")
      setContractDocument("")
      setAuthorisationExpDate("")
      setNote("")
      setIsEdit(false)
      setEditableItem({})
      setPassword("");
      setEmail("");
      setSelectedVehicle('')
      setUpdatedVehicle('')
    }

    if(modal){
      setactiveTab("1");
      setCaptainUser({});
      const _searchText = searchText;
      const _password = password;
      const _email = email;
      setTimeout(() => {
        setSearchText(_searchText);
        setPassword(_password);
        setEmail(_email);
        setError({
          ...error,
          password : false
        })
        if(editableItem?._id != undefined){
          //get user details of selected Laundry
          dispatch(filterUsers({
            web: true,
            driver : editableItem._id
          }));
        }

      }, 1000);
    }
  }, [modal])

  useEffect(() => {
    setDriverDataList(drivers)
  }, [drivers])

  const handleSearch = (val) => {
    val = val?.toLowerCase()
    let filterBySearch = drivers.filter((item) => item?.name?.toLowerCase()?.includes(val) || item?.availability?.findIndex((data) => data?.area?.name?.toLowerCase()?.includes(val)) !== -1 || (item?.online && val == "On") || (!item?.online && val == "Off"));
    if (!val) {
      setDriverDataList(drivers)
    } else {
      setDriverDataList(filterBySearch)
    }
    // console.log("filterBySearch", filterBySearch);
  }

  const handleOnchange = (val) => {
    setvalue(val ? val.split(",") : [])
  }


  useEffect(() => {
    let areaListFilter = areaList.filter((item) => value1.includes(item._id))
    setSelectedAreasList(areaListFilter);
    let newArrayAvalibility = []
    areaListFilter.map((item) => {
      let oldAvailability = [...availability]
      let oldAvailabilityIndex = oldAvailability.findIndex((data) => data.area === item._id);
      if (oldAvailabilityIndex !== -1) {
        newArrayAvalibility.push(oldAvailability[oldAvailabilityIndex]);
      } else {
        newArrayAvalibility.push({ area: item._id, days: [0, 1, 2, 3, 4, 5, 6] });
      }
    })
    setAvailability(newArrayAvalibility)
  }, [value1])

  const handleAvailability = (e, areaID) => {
    const selectedIndex = parseInt(e.target.value);

    let selectedAvailibility = [...availability]
    let findAvailabilityData = selectedAvailibility.findIndex((item) => item.area == areaID)
    if (findAvailabilityData !== -1) {
      let selectedDays = selectedAvailibility[findAvailabilityData]?.workdays ? selectedAvailibility[findAvailabilityData]?.workdays : [];
      const dayIndex = selectedDays.findIndex(workday => workday.day ==  selectedIndex);

      if (dayIndex !== -1) {
       selectedDays.splice(dayIndex, 1);
      } else {
        selectedDays.push({
          day : selectedIndex,
          pickup : true, 
          delivery: true
        })
      }
      selectedAvailibility[findAvailabilityData].workdays = selectedDays;
    } else {
      selectedAvailibility.push({ 
        area: areaID, 
        workdays: [{
          day : selectedIndex,
          pickup : true, 
          delivery : false
        }] 
      })
    }
    setAvailability(selectedAvailibility)
  }

  const isDayChecked = (workdays, day) => {
    if(workdays == undefined)
      return false;
    //availability[areaIndex]?.workdays?day.includes(index)
    const found = workdays.find(d => d.day == day);
    return found?true:false;
  }

  const daywiseChecked = (workdays, day, type) => {
    if(workdays == undefined)
      return false;
    const found = workdays.find(d => d.day == day);
    if(found){
      return found[type]==true?true: false;
    }
    else{
      return false;
    }
  }

  const setDaywiseChecked = (value, areaIndex, day, type) => {
    let selectedAvailibility = [...availability]

    let selectedDays = selectedAvailibility[areaIndex]?.workdays ? selectedAvailibility[areaIndex]?.workdays : [];
    const dayIndex = selectedDays.findIndex(workday => workday.day == day);

    if (dayIndex !== -1) {
      selectedAvailibility[areaIndex].workdays[dayIndex][type] = value;
    }

    setAvailability(selectedAvailibility);
  }

  useEffect(() => {
    if(!password) 
      return;
    let error = false;
    if(password && password.trim() == ""){
      if(!captainUser?._id){
        error = true;
      }
    }
    else{
      if(!validatePassword(password)){
        error = true;
      }
    }
    setError({
      ...error, 
      password: error
    })

  }, [password]);

  useEffect(() =>{
    if(!email) 
    return;

    if(!validateEmail(email)){
      setError({
        ...error, 
        email: true
      })
    }
    else{
      setError({
        ...error, 
        email: false
      })
    }
  }, [email])

  const addUpdateUser = async () => {
    let valid = true;
    let _validObj = {email : false, password : false};
    //Validate user details
    if(!validateEmail(email)){
      valid = false;
      _validObj.email = true;
    }

    if(password && password.trim() == "" || !password){
      if(!captainUser?._id){
        valid = false;
        _validObj.password = true;
      }
    }
    else{
      if(!validatePassword(password)){
        valid = false;
        _validObj.password = true;
      }
    }


    setError({
      ...error, 
      ..._validObj
    });

    if(!valid){
      return;
    }

    let userResponse = {};
    if(captainUser?._id){
      const user = {
        _id : captainUser._id,
        email : email, 
        driver: editableItem._id
      }
      if(password && password.trim() != ""){
        user.password = password;
      }
      userResponse = await updateCredentials(user);
    }
    else{
      userResponse = await postAddUser({
        email : email, 
        password : password, 
        driver: editableItem._id,
        role: "driver",
        web: true
      });

      if(userResponse.statusCode == 200){
        const _captainUser = userResponse.record;
        _captainUser.driver = editableItem._id;
        setCaptainUser(_captainUser);
      }
    }

    if(userResponse.statusCode == 200){
      toastr.success(captainUser?._id?"User Updated Successfully":"User Added Successfully", "Success");
      toggle();
    }
    else{
      toastr.error(userResponse?.msg?userResponse?.msg:"Error in saving user details", "Error");
    }
  }

  useEffect(() => {
    const data = {}
    dispatch(getDriverList(data))
  }, [])

  useEffect(() => {
    const data = {}
    dispatch(searchUsers({ webPermissions: true }))
    dispatch(getLaundries(data))
  }, [])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])


  useEffect(async () => {
    const AreaResponse = await postArea({});
    setAreaList(AreaResponse?.record);
    setAreaOption(
      AreaResponse?.record?.map((item) => {
        return { label: item?.name, value: item?._id }
      })
    )
  }, [])


  const selectRow = {
    mode: "checkbox",
  };

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: driverDataList.length, // replace later with size(orders),
    custom: true,
  };
  const { SearchBar } = Search;

  const handleEditCaptain = (item) => {
    setEditableItem(item)
    setIsEdit(true);
    setStatusVal(item.status)
    setOnlineVal(item.online)
    setIsFaceIdRequired(item.isFaceIdRequired ?? false)
    setIsFaceIdEnabled(item.isFaceIdEnabled ?? false)
    let availabilityValue = [];
    let areaValue = [];
  
    if (item?.availability && item?.availability.length) {
      item.availability.map((item) => {
        availabilityValue.push({
          area: item?.area?._id,  
          days: item.days,         
          workdays: item.workdays
        });
        areaValue.push(item?.area?._id); 
      });
    }
    setAvailability(availabilityValue);
    let areaListFilter = areaList.filter((item) => areaValue.includes(item._id))
    setSelectedAreasList(areaListFilter);
    setvalue(areaValue)
    setDriverName(item.name)
    setMobile(item.mobile)
    setId(item.id)
    setIdExpDate(item.idExpDate)
    setLicense(item.license)
    setLicenseExpDate(item.licenseExpDate)
    setLicenseType(item.licenseType)
    setLicenseNumber(item.licenseNumber)
    setEmployementType(item.employementType)
    setSalary(item.salary)
    setHiringMethod(item.hiringMethod)
    setContractDocument(item.contractDocument)
    setAuthorisationExpDate(item.authorisationExpDate)
    setNote(item.note)
    setPickup(item.pickup)
    setDelivery(item.delivery)
    setModal(true)
    setSelectedVehicle(item?.vehicle === null ? "none" : item?.vehicle?._id);
    setUpdatedVehicle(item?.vehicle === null ? "none" : item?.vehicle?._id);
    setHealthCertificate(item.healthCertificate)
    setCertificateNumber(item.healthCertificateNumber)
    setCertificateIssueDate(item.healthCertificateIssueDate)
    setCertificateExpirationDate(item.healthCertificateExpireDate)
  }

  useEffect(() => {
    if (!modal) {
      setError({})
    }
  }, [modal])

  let EcommerceOrderColumns = [
    {
      name: "Name",
      sortable: true,
      selector: row => row.name,
      wrap: true,
    }, 
    {
      name: "Vehicle",
      sortable: true,
      selector: (row) => {
        return row?.vehicle?._id ? (row?.vehicle?.plateNumber || "") + " (" +(row?.vehicle?.model || "") + "-" + (row?.vehicle?.type || "") + ")" : "None";
      },
    } ,
    {
      name: "Area",
      classes: "reason-wrap",
      sortable: true,
      wrap: true,
      center: true,
      selector: row => (
        <div>
          {row?.availability?.map((item2, index) => {
            return item2?.area?.name + " | "
          })}
        </div>
      ),

    }, {
      name: "Online",
      sortable: true,
      center: true,
      selector: row => (
        <div>
          {row.online ? "On" : "Off"}
        </div>
      ),
    },
    {
      name: "Status",
      sortable: true,
      center: true,
      selector: row => (
        <div>
          {row.status ? "On" : "Off"}
        </div>
      ),
    },
    {
      name: "Face ID Enabled",
      sortable: true,
      center: true,
      selector: row => (
        <div>
          {row.isFaceIdEnabled ? "Yes" : "No"}
        </div>
      ),
    }
  ];

  if (userData?.permissions?.allPermissions == true || userData?.permissions?.captains?.update == true) {
    EcommerceOrderColumns.push({
      name: "View",
      center: true,
      selector: row => {
        return <>
          <button className="btn btn-primary btn-sm" onClick={() => handleEditCaptain(row)}>
            View
          </button>
          
        </>
      }
    })
  }

  const toggle = () => {
    setModal(!modal);
    setSelectedVehicle(null);
  };
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const validateMobile = (number) => {
    let regex;
    let valid = true
    if (phCode == 91) {
      // India: 10 digits, starting with 6, 7, 8, or 9
      regex = /^[6-9]\d{9}$/;
    } else if (phCode == 966) {
      // Saudi Arabia: 9 digits, or 10 if starting with 0
      regex = /^0?\d{9}$/;
    } else {
      // Handle other cases or default to invalid
      valid = false
      setError({ ...error, mobile: "Invalid country code." });
    }

    if (number === "" || regex.test(number)) {
      valid = true
      setError({ ...error, mobile: '' });
    } else {
      valid = false
      setError(
        {
          ...error, mobile: phCode == 91
            ? "Mobile number must be 10 digits long and start with 6, 7, 8, or 9."
            : "Mobile number must be 9 digits long, or 10 if starting with 0."
        }
      );
    }
    return valid;
  };

  const handleInvalidSubmit = (event, errors, values) => {
    const fieldsToCheck = [
      'name',
      'mobile',
      'id',
      'idExpDate',
      'note',
      'licenseType',
      'licenseNumber',
      'licenseExpDate'
    ];
    const hasError = fieldsToCheck.some(field => errors.includes(field));
    if(hasError){
      basicInfoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if(errors?.length > 0){
      employementTypeRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    return
  }

  const submitNewDriver = async () => {
    if (value1?.length < 1) {
      setError({ area: "Area Required" })
      return
    }
    // return
    let data = {
      name: driverName,
      id: id,
      idExpDate: idExpDate,
      license: license,
      licenseNumber: licenseNumber,
      licenseExpDate: licenseExpDate,
      licenseType: licenseType,
      healthCertificate: healthCertificate,
      healthCertificateNumber: certificateNumber,
      healthCertificateIssueDate: certificateIssueDate,
      healthCertificateExpireDate: certificateExpirationDate,
      employementType: employementType,
      salary: salary,
      hiringMethod: hiringMethod,
      contractDocument: contractDocument,
      authorisationExpDate: authorisationExpDate,
      note: note,
      status: statusVal,
      online: onlineVal,
      // area: value1,
      availability: JSON.stringify(availability),
      pickup: pickup,
      delivery: delivery,
      vehicle: isEdit ? updatedVehicle === "none" ? null : updatedVehicle : selectedVechicle === "none" ? null : selectedVechicle,
      isFaceIdRequired: isFaceIdRequired === true ? true : false
    }

    if(mobile){
      const isValid = await validateMobile(Number(mobile))
      if(isValid){
        data['mobile'] = mobile
      } else{
       setError({ mobile: "Invalid Mobile Number" })
        return
      }
    }


    if(contractDocument){
      data['contractDocument'] = contractDocument
    }

    let captainResponse = {}, resMsg;

    const formData = new FormData();
    // Append data to FormData
    Object.keys(data).forEach((key) => {
      if (data[key] !== null && data[key] !== undefined) {
        formData.append(key, data[key]);
      }
    });

    if (isEdit) {
      if(updatedVehicle == null || updatedVehicle == "Select Vehicle") {
        setError({ vehicle: "Vehicle Required, select none if no vehicle" })
        basicInfoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          return
      }
        
        formData.append('_id', editableItem._id);  
      captainResponse = await updateDrivers(formData);
      if(captainResponse.statusCode == 200){
        toggle()
        setUpdatedVehicle(null)
      }
      resMsg = captainResponse.statusCode == 200 ? "Captain Updated" : captainResponse?.msg ?? "Unable to Update Captain";
    } else {
      if(selectedVechicle == null || selectedVechicle == "Select Vehicle") {
        setError({ vehicle: "Vehicle Required, select none if no vehicle" })
        basicInfoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        return
      }
      captainResponse = await addDrivers(formData);
      if(captainResponse?.statusCode == 200){
        resMsg = "Captain Added";
        setIsEdit(true);
      }
      else{
        resMsg = captainResponse?.msg ?? "Unable to Add Captain";
      }
    }

    captainResponse.statusCode==200?toastr.success(resMsg, "Success"):toastr.error(resMsg, "Error");

    dispatch(getDriverList({}))

   // toggle()
    if(!isEdit && captainResponse?.statusCode == 200){
      //Show user details
      const newCaptainData = captainResponse?.record;
      setEditableItem(newCaptainData);
      setUpdatedVehicle(selectedVechicle);
      setactiveTab("2");  
    }

  }

  var node = useRef();
  const handleOrderClicks = () => {
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "orderID",
      order: "desc",
    },
  ];

  useEffect(() => {
    handleSearch(searchText);
  }, [searchText])

  useEffect(() => {
    if(filteredUsers.length > 0){
      setEmail(_email => filteredUsers[0]?.email);
      setCaptainUser(filteredUsers[0]);
    }
  }, [filteredUsers]);

  const [vehicles,setVehicles] = useState([]);
  const [selectedVechicle,setSelectedVehicle] = useState(null);
  const fetchVehicles = async () => {
    try {
      const response = await postWeb('/vehicles/search', {
        captains: true
      });
      if (response?.statusCode == 200) {
        setVehicles(response.data); // Set the vehicles data for the current page
      } else {
        console.log('error', response);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
    }
  };


  const healthCerificateInputRef = useRef(null); 
  const licenceInputRef = useRef(null); 
  const contractDocumentInputRef = useRef(null);

  const handleFileChange = (e) => {
    if(e?.target?.name == 'license') {
      setLicense(e.target.files[0]);
    }
    if(e?.target?.name == 'contractDocument') {
      setContractDocument(e.target.files[0]);
    }
    if(e.target?.name == 'healthCertificate') {
      setHealthCertificate(e.target.files[0]);
    }
  };

  const triggerFileInput = (field) => {
    if(field == 'healthCertificate') {
      healthCerificateInputRef.current.click();
    } 
    if(field == 'contractDocument') {
      contractDocumentInputRef.current.click();
    }
    if(field == 'license') {
      licenceInputRef.current.click();
    }
  };


  useEffect(() => {
    fetchVehicles();
  }, []);

  if (userData?.permissions?.allPermissions == true || userData?.permissions?.captains?.read == true) {
    return (
      <React.Fragment>
        {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
        <div className="page-content">
          <MetaTags>
            <title>Captains  </title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Captains" />
            <Card>
              <CardBody>
                <Row className="mb-2">
                  <Col sm="6">
                    <div className="me-2 mb-2 d-inline-block">
                      <div className="position-relative d-flex">
                        <input
                          id="search-bar-10"
                          type="text"
                          aria-labelledby="search-bar-10-label"
                          className="form-control "
                          placeholder="Search"
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                        <div className="ms-2" style={{ width: "20rem" }}>
                          <Select
                            placeholder="All Areas"
                            onChange={val => {
                              setSelectedArea(val)
                            }}
                            isClearable={true}
                            options={areas}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="text-sm-end d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center mx-3">
                    <span className={`me-2 ${fetchStatusTrue? "text-success font-weight-semibold": ""}`}>Show Enabled</span>
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input form-slider-color-green"
                            defaultChecked={fetchStatusTrue}
                            onChange={(e) => { setFetchStatusTrue(!fetchStatusTrue) }}
                          />
                        </div>
                      </div>
                    <div className="d-flex align-items-center">
                    <span className={`me-2 ${fetchStatusFalse? "text-success font-weight-semibold": ""}`}>Show Disabled</span>
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input form-slider-color-green"
                            defaultChecked={fetchStatusFalse}
                            onChange={(e) => { setFetchStatusFalse(!fetchStatusFalse) }}
                          />
                        </div>
                      </div>
                      </div>
                      {(userData?.permissions?.allPermissions == true || userData?.permissions?.captains?.create == true) ?
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded  mb-2 me-2"
                          onClick={handleOrderClicks}
                        >
                          <i className="mdi mdi-plus me-1" />
                          Add Captain
                        </Button> : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <DataTable
                    columns={EcommerceOrderColumns}
                    data={driverDataList
                      .filter((item) => {
                        return filterItem(item)
                      }).filter(slot => {
                        if (!selectedArea) {
                          return true
                        }
                        return slot.availability
                          .map(availability => availability?.area?._id)
                          .includes(selectedArea?.value)
                      })
                    }
                    pagination
                  />
                </Row>
              </CardBody>
            </Card>
            <Modal isOpen={modal} toggle={toggle} size="xl">
              <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? "Edit Captain" : "Add Captain"}
              </ModalHeader>
              <ModalBody>
                
                  <Nav tabs className="mt-2 ms-2">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Captain Info
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                        disabled={!editableItem?._id}
                      >
                        User
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab} className="px-3 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                          <AvForm
                              onValidSubmit={submitNewDriver}
                              onInvalidSubmit={handleInvalidSubmit}
                              autoComplete="off"
                            >
                            <Row form>
                              <Col>
                                <div ref={basicInfoRef}>
                                  <Row lg='12'>
                                    <SectionTitle title="Basic Info" />
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <AvField
                                          name="name"
                                          label={<div>Name <span className="mandatory_feild">*</span></div>}
                                          type="text"
                                          errorMessage="Name Required"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          onChange={(e) => { setDriverName(e.target.value) }}
                                          value={driverName}
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <AvField
                                          name="mobile"
                                          label={<div>Phone Number <span className="mandatory_feild">*</span></div>}
                                          type="text"
                                          validate={isEdit ? {} : {
                                            required: { value: true, errorMessage: 'Phone Number Required' },
                                            pattern: {
                                              value: (phCode == 91) ? /^[6-9]\d{9}$/ : /^0?\d{9}$/,
                                              errorMessage: (phCode == 91) ? "Mobile number must be 10 digits long and start with 6, 7, 8, or 9." : "Mobile number must be 9 digits long, or 10 if starting with 0."
                                            }
                                            
                                          }}
                                          onChange={(e) => { setMobile(e.target.value) }}
                                          value={mobile}
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="4">
                                      <div className="mb-3">

                                        <AvField
                                          name="id"
                                          label={<div>ID  </div>}
                                          type="text"
                                          onChange={(e) => { setId(e.target.value) }}
                                          value={id}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row lg='12'>

                                    
                                    <Col lg="4">
                                      <div className="mb-3">

                                        <AvField
                                          name="idExpDate"
                                          label={<div>ID Expiration </div>}
                                          type="date"
                                          onChange={(e) => { setIdExpDate(e.target.value) }}
                                          value={idExpDate}
                                          min={new Date().toISOString().split("T")[0]}
                                          errorMessage="ID expiry date cannot be in the past."
                                          validate={{
                                            min: {
                                              value: idExpDate ? new Date().toISOString().split("T")[0] : "",
                                              errorMessage: "Expiration date should be greater than today"
                                            }
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <AvField
                                          name="note"
                                          label={<div>Note</div>}
                                          type="text"
                                          onChange={(e) => { setNote(e.target.value) }}
                                          value={note}
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="4" className="d-flex mt-4">
                                      <div className="d-flex mx-4">
                                        <label
                                          htmlFor="horizontal-status-Input1"
                                          className="status-label mt-2"
                                        >Status
                                        </label>
                                        <div className="form-switch">
                                          <Switch
                                            uncheckedIcon={<Offsymbol />}
                                            checkedIcon={<OnSymbol />}
                                            className="me-1 mb-sm-8 mb-2"
                                            onColor="#626ed4"
                                            onChange={(e) => setStatusVal(!statusVal)}
                                            checked={statusVal}
                                          />
                                        </div>
                                      </div>
                                      <div className="d-flex">
                                        <label
                                          htmlFor="horizontal-status-Input"
                                          className="status-label mt-2"
                                        >Online</label>
                                        <div className="form-check form-switch">
                                          <Switch
                                            uncheckedIcon={<Offsymbol />}
                                            checkedIcon={<OnSymbol />}
                                            className="me-1 mb-sm-8 mb-2"
                                            onColor="#626ed4"
                                            onChange={(e) => setOnlineVal(!onlineVal)}
                                            checked={onlineVal}
                                          />

                                        </div>
                                      </div>
                                    </Col>
                                    <SectionTitle title="Face Authentication" 
                                  />
                                    <Col lg="4" className="d-flex">
                                      <div className="d-flex col align-items-center gap-2">
                                        <label
                                          htmlFor="horizontal-status-Input"
                                          className="status-label mt-2"
                                        >Face ID Enabled :- </label>
                                        <div>
                                          {
                                            isFaceIdEnabled ? (
                                              <span className="badge bg-success p-2">Yes</span>
                                            ) : (
                                              <span className="badge bg-danger p-2">No</span>
                                            )
                                          }
                                        </div>
                                      </div>
                                    </Col>
                                    <Col lg="4" className="d-flex">
                                      <div className="d-flex">
                                        <label
                                          htmlFor="horizontal-status-Input"
                                          className="status-label mt-2"
                                        >Is Face ID Required</label>
                                        <div className="form-check form-switch">
                                          <Switch
                                            uncheckedIcon={<Offsymbol />}
                                            checkedIcon={<OnSymbol />}
                                            className="me-1 mb-sm-8 mb-2"
                                            onColor="#626ed4"
                                            onChange={(e) => setIsFaceIdRequired(!isFaceIdRequired)}
                                            checked={isFaceIdRequired}
                                          />

                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                                 
                                <div>
                                  <SectionTitle title="Vehicle Info" 
                                  />
                                  <Row lg='12'>
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <label>Vehicle</label>
                                        <select className="form-select form-control" aria-label="Default select example"
                                          onChange={(e) => {
                                            setError({ vehicle: "" })
                                            isEdit ? setUpdatedVehicle(e.target.value) : setSelectedVehicle(e.target.value)
                                          }}
                                          value={updatedVehicle ? updatedVehicle : selectedVechicle}
                                        >
                                          <option selected>Select Vehicle</option>
                                          <option value="none">None</option>
                                          {
                                            vehicles.map((vehicle) => {
                                              return <option value={vehicle?._id} key={vehicle?._id}>{(vehicle?.plateNumber || "") + "(" + (vehicle?.model || "") + "-" + (vehicle?.type || "") + ")"}</option>
                                            })
                                          }
                                        </select>
                                        {
                                          error?.vehicle ? (
                                            <label style={{ color: "red" }}>{error?.vehicle}</label>
                                          ) : null
                                        }
                                      </div>
                                    </Col>
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <label>License Type</label>
                                        <AvField
                                          type="select"
                                          name="licenseType"
                                          value={licenseType}

                                          className="form-control"
                                          onChange={(e) => setLicenseType(e.target.value)}

                                          helpMessage="">
                                          <option value="">Selet Type</option>
                                          <option value="private" selected>Private</option>
                                          <option value="public">Public</option>
                                          <option value="heavy">Heavy</option>

                                        </AvField>
                                      </div>
                                    </Col>
                                    <Col lg="4">
                                      <Label>License</Label>
                                      <div className="mb-3">
                                        <Button color="primary" outline style={{marginRight:'5px'}} onClick={()=>triggerFileInput('license')}>
                                          {license?.name ||  license?.split('/').pop() ? 'Change':
                                            <>
                                              <i className="fa fa-upload h-4 w-4 px-1" /> Upload
                                            </>
                                          }
                                        </Button>
                                        <input
                                          name="license"
                                          type="file"
                                          ref={licenceInputRef}
                                          accept="image/*,application/pdf"
                                          style={{ display: "none" }}
                                          onChange={handleFileChange}
                                        />
                                        <span className="ml-2">{
                                            isEdit && license && (typeof license == 'string') && license.includes('http') ?
                                            <a
                                              href={license}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="text-primary text-decoration-underline">
                                              View Uploaded File
                                            </a>
                                            : license?.name || license?.split('/').pop()
                                          }</span>
                                      </div>
                                    </Col>
                                    <Col lg="4">
                                      <div className="mb-3">

                                        <AvField
                                          name="licenseNumber"
                                          label={<div>License Number  </div>}
                                          type="text"
                                          onChange={(e) => { setLicenseNumber(e.target.value) }}
                                          value={licenseNumber}
                                        />
                                      </div>
                                    </Col>
                                    
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <AvField
                                          name="licenseExpDate"
                                          label={<div>License Expiration</div>}
                                          type="date"
                                          onChange={(e) => { setLicenseExpDate(e.target.value) }}
                                          value={
                                            isEdit && licenseExpDate ? new Date(licenseExpDate).toISOString().split("T")[0] : ""
                                          }
                                          min={isEdit ? "" : new Date().toISOString().split("T")[0]}
                                          errorMessage="License expiry date cannot be in the past."
                                          validate={isEdit ? {} :{
                                            min: {
                                              value: licenseExpDate ? new Date().toISOString().split("T")[0] : "",
                                              errorMessage: "Expiration date should be greater than today"
                                            }
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                </div>
                                <div>
                                  <SectionTitle title="Employment" />
                                  <div ref={employementTypeRef}/>
                                  <Row>
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <label>Employement Type</label>
                                        <AvField
                                          type="select"
                                          name="employementType"
                                          value={employementType}

                                          className="form-control"
                                          onChange={(e) => setEmployementType(e.target.value)}

                                          helpMessage="">
                                          <option value="">Selet Type</option>
                                          <option value="full time" selected>Full Time</option>
                                          <option value="part time">Part Time</option>
                                          <option value="freelance">Freelance</option>

                                        </AvField>
                                      </div>
                                    </Col>
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <AvField
                                          name="salary"
                                          label={<div>Salary</div>}
                                          type="number"
                                          onChange={(e) => { setSalary(e.target.value) }}
                                          value={salary}
                                        />
                                      </div>
                                    </Col>
                                    <Col lg="4">
                                      <div className="mb-3">
                                        <label>Method of Hiring</label>
                                        <AvField
                                          type="select"
                                          name="hiringMethod"
                                          value={hiringMethod}

                                          className="form-control"
                                          onChange={(e) => setHiringMethod(e.target.value)}

                                          helpMessage="">
                                          <option value="">Selet Method</option>
                                          <option value="haraj" selected>Haraj</option>
                                          <option value="wom">Wom</option>


                                        </AvField>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="4">
                                  <Label>Contract Document</Label>
                                  <div className="mb-3 d-flex">
                                    <Button outline style={{marginRight:'5px'}} color="primary" onClick={()=>triggerFileInput('contractDocument')}>
                                    {contractDocument?.name || contractDocument?.split('/').pop()? 'Change': 
                                      <>
                                        <i className="fa fa-upload h-4 w-4 px-1" /> Upload
                                      </>
                                    }
                                    </Button>
                                    <input
                                      type="file"
                                      name="contractDocument"
                                      ref={contractDocumentInputRef}
                                      accept="image/*,application/pdf"
                                      style={{ display: "none" }}
                                      onChange={handleFileChange}
                                    />
                                    <span >{
                                      isEdit && contractDocument && (typeof contractDocument == 'string') && contractDocument.includes('http') ?
                                      <a
                                        href={contractDocument}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-primary text-decoration-underline">
                                        View Uploaded File
                                      </a>
                                      : contractDocument?.name || contractDocument?.split('/').pop()  
                                    }</span>
                                  </div>
                                </Col>
                                <Col lg="4">
                                        <div className="mb-3">
                                          <AvField
                                            name="authorisationExpDate"
                                            label={<div>Authorization Expiration Date</div>}
                                            type="date"
                                            onChange={(e) => { setAuthorisationExpDate(e.target.value) }}
                                            value={
                                              isEdit && authorisationExpDate ? new Date(authorisationExpDate).toISOString().split("T")[0] : authorisationExpDate
                                            }
                                            errorMessage="Authorization expiry date cannot be in the past."
                                            min={isEdit ? "" :new Date().toISOString().split("T")[0]}
                                            validate={isEdit ? {} :{
                                              min: {
                                                value: authorisationExpDate ? new Date().toISOString().split("T")[0] : "",
                                                errorMessage: "Expiration date should be greater than today"
                                              }
                                            }}
                                            />
                                        </div>
                                      </Col>
                                    </Row>
                                </div>
                                
                                <div >
                                  <SectionTitle title="Health Certificate" />
                                  <Row lg='12'>
                                    <Col lg="3">
                                      <Label> Certificate</Label>
                                      <div className="mb-3">
                                        <Button color="primary" outline style={{marginRight:'5px'}} onClick={()=>triggerFileInput('healthCertificate')}>
                                        {healthCertificate?.name || healthCertificate?.split('/').pop() ? 'Change': 
                                          <>
                                            <i className="fa fa-upload h-4 w-4 px-1" /> Upload
                                          </>
                                        }
                                        </Button>
                                        <input
                                          name="healthCertificate"
                                          type="file"
                                          ref={healthCerificateInputRef}
                                          accept="image/*,application/pdf"
                                          style={{ display: "none" }}
                                          onChange={handleFileChange}
                                        />
                                        <span className="ml-2">{
                                          isEdit && healthCertificate && (typeof healthCertificate == 'string') && healthCertificate.includes('http') ?
                                          <a
                                            href={healthCertificate}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-primary text-decoration-underline">
                                            View Uploaded File
                                          </a>
                                          : healthCertificate?.name || healthCertificate?.split('/').pop()  
                                        }</span>
                                      </div>
                                    </Col>
                                    <Col lg="3" className="mt-3">
                                      <Label for="certificateNumber">Certificate Number</Label>
                                      <input
                                        type="text"
                                        id="certificateNumber"
                                        className="form-control"
                                        placeholder="Enter certificate number"
                                        value={certificateNumber}
                                        onChange={(e) => setCertificateNumber(e.target.value)}
                                      />
                                    </Col>

                                    <Col lg="3" className="mt-3">
                                      <AvField
                                        name="certificateIssueDate"
                                        label="Issue Date"
                                        type="date"
                                        value={
                                          isEdit && certificateIssueDate ? new Date(certificateIssueDate).toISOString().split('T')[0] : certificateIssueDate
                                        }
                                        max={isEdit ? "" : new Date().toISOString().split('T')[0]}
                                        onChange={(e) => setCertificateIssueDate(e.target.value)}
                                        validate={isEdit ? {} : {
                                          required: {
                                            value: certificateIssueDate ? true : false,
                                            errorMessage: "Please select an issue date"
                                          }
                                        }}
                                        errorMessage="Please select a valid issue date"
                                      />
                                    </Col>

                                    <Col lg="3" className="mt-3">
                                      <AvField
                                        name="certificateExpirationDate"
                                        label="Expiration Date"
                                        type="date"
                                        value={
                                          isEdit && certificateExpirationDate ? new Date(certificateExpirationDate).toISOString().split('T')[0] : certificateExpirationDate
                                        }
                                        min={isEdit ? "" : new Date().toISOString().split('T')[0]}
                                        onChange={(e) => setCertificateExpirationDate(e.target.value)}
                                        validate={isEdit ? {} :{
                                          min: {
                                            value: certificateExpirationDate ? new Date().toISOString().split('T')[0] : "",
                                            errorMessage: "Expiration date should be greater than today" 
                                          },
                                        }}
                                        errorMessage="Please select a valid expiration date"
                                      />
                                    </Col>
                                  </Row>
                                </div>
                                
                                <div>
                                  <SectionTitle title="Availiblity" />
                                    <Row>
                                      <Col lg="6">
                                      <div className="mb-3">
                                          <label>Areas <span className="mandatory_feild">*</span></label>
                                          <MultiSelect
                                            // selectedValues={value1}
                                            defaultValue={value1.toString()}
                                            className="multi-selects form-selects2 form-controlss areas-box"
                                            onChange={handleOnchange}
                                            options={areaOption}
                                          />
                                          {
                                            error?.area ? (
                                              <label style={{ color: "red" }}>{error?.area}</label>
                                            ) : null
                                          }
                                        </div>
                                      </Col>
                                      <Col lg="6" className="d-flex mt-4">
                                        {/* <div className="d-flex mx-4">
                                          <label
                                            htmlFor="horizontal-status-Input1"
                                            className="status-label mt-2"
                                          >Pickup
                                          </label>
                                          <div className="form-switch">
                                              <Switch
                                                uncheckedIcon={<Offsymbol />}
                                                checkedIcon={<OnSymbol />}
                                                className="me-1 mb-sm-8 mb-2"
                                                onColor="#626ed4"
                                                onChange={(e) => setPickup(!pickup)}
                                                checked={pickup}
                                              />
                                          </div>
                                        </div>
                                        <div className="d-flex">
                                          <label
                                            htmlFor="horizontal-status-Input"
                                            className="status-label mt-2"
                                          >Delivery</label>
                                          <div className="form-check form-switch">
                                            <Switch
                                              uncheckedIcon={<Offsymbol />}
                                              checkedIcon={<OnSymbol />}
                                              className="me-1 mb-sm-8 mb-2"
                                              onColor="#626ed4"
                                              onChange={(e) => setDelivery(!delivery)}
                                              checked={delivery}
                                            />

                                          </div>
                                        </div> */}
                                        
                                        
                                      </Col>
                                    </Row>
                                </div>
                                    
                                </Col>
                                
                                <div className="row mb-2">
                                  {
                                    selectedAreasList?.map((data, areaIndex) => {
                                      return (
                                        <Col className="col-12" key={data?._id}>
                                          <Label
                                            className="form-check-Label"
                                            htmlFor="formrow-customCheck"
                                          >{data.name}</Label>
                                          <div className="mb-3 d-flex">
                                            {
                                              Weeks?.map((item, index) => {
                                                const dayChecked = isDayChecked(availability[areaIndex]?.workdays, index);
                                                return (
                                                  <>
                                                  <Card outline color="info" className="border mb-2 me-2">
                                                  <CardBody>
                                                    <div className="d-flex mb-1" key={index}>
                                                      <Label
                                                        className="form-check-Label"
                                                        htmlFor={areaIndex + "" + item}
                                                      >{item}</Label>
                                                      <div className="form-check mx-1">
                                                        <Input
                                                          type="checkbox"
                                                          className="form-check-Input"
                                                          id={areaIndex + "" + item}
                                                          value={index}
                                                          defaultChecked={dayChecked}
                                                          onChange={(e) => handleAvailability(e, data._id)}
                                                        />
                                                      </div>
                                                    </div>
                                                    {dayChecked?
                                                    <>
                                                    <div className="d-flex align-items-center mb-2">
                                                      <label
                                                        htmlFor="horizontal-status-Input1"
                                                        className="status-label mb-0"
                                                        style={{fontSize:10}}
                                                      >Pick &nbsp;
                                                      </label>
                                                      <div className="form-switch ps-1">
                                                          <Switch
                                                            uncheckedIcon={<OffNoText />}
                                                            checkedIcon={<OnNoText />}
                                                            className="me-1 mb-sm-8"
                                                            onColor="#626ed4"
                                                            onChange={(e) =>  setDaywiseChecked(e, areaIndex, index, "pickup")}
                                                            checked={daywiseChecked(availability[areaIndex]?.workdays, index, "pickup")}
                                                            height={11}
                                                            width={25}
                                                            
                                                          />
                                                      </div>
                                                    </div>

                                                      <div className="d-flex align-items-center">
                                                      <label
                                                        htmlFor="horizontal-status-Input1"
                                                        className="status-label mb-0"
                                                        style={{fontSize:10}}
                                                      >Drop
                                                      </label>
                                                      <div className="form-switch ps-1">
                                                          <Switch
                                                            uncheckedIcon={<OffNoText />}
                                                            checkedIcon={<OnNoText />}
                                                            className="me-1 mb-sm-8"
                                                            onColor="#626ed4"
                                                            onChange={(e) =>  setDaywiseChecked(e, areaIndex, index, "delivery")}
                                                            checked={daywiseChecked(availability[areaIndex]?.workdays, index, "delivery")}
                                                            height={11}
                                                            width={25}
                                                          />
                                                      </div>

                                                    </div>
                                                    </>: null}
                                                    </CardBody>
                                                  </Card>
                                                  </>
                                                )
                                              })
                                            }
                                          </div>
                                          {/* {
                                                              areaList?.map((item) => {
                                                                return (
                                                                  <Label key={item?._id} check>
                                                                    <AvInput type="checkbox" name="areas" onChange={(e) => { ArrayArea(item?._id) }} value={item?._id.toString()} /> {item?.name}
                                                                  </Label>
                                                                )
                
                                                              })
                                                            } */}
                                        </Col>
                                      )
                                    })
                                  }
                                </div>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-end">
                                    <button
                                      type="submit"
                                      className="btn btn-success save-user"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </AvForm>
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            <Row>
                              <Col sm="12">
                                <CardText className="mb-0">
                                
                                  <div className="row mb-2">
                                    
                                    <Col sm={6}>
                                      <Label
                                        htmlFor="horizontal-email-Input"
                                        className="col-sm-3 col-form-label"
                                        >
                                        Email
                                        </Label>
                                        <Input
                                          type="email"
                                          name="email"
                                          className={"form-control " + (error?.email?"is-invalid":"")}
                                          id="horizontal-email-Input"
                                          placeholder="Email"
                                          errorMessage="Invalid Email"
                                          autoComplete={false}
                                          value={email}
                                          onChange={(e) => { setEmail(e.target.value) }}
                                          
                                        />
                                        {
                                          error?.email ? (
                                            <div className="invalid-feedback">Enter valid email</div>
                                          ) : null
                                        }
                                    </Col>
                                  </div>

                                  <div className="row mb-2">
                                    <Col sm={6}>
                                      <Label
                                        htmlFor="horizontal-1-Input"
                                        className="col-sm-3 col-form-label"
                                      >
                                        Set Password
                                      </Label>
                                      <input
                                        type="password"
                                        name="pass"
                                        className={"form-control " + (error?.password?"is-invalid":"")}
                                        autoComplete={false}
                                        id="horizontal-password-Input"
                                        value={password}
                                        onChange={(e) => { setPassword(e.target.value) }}
                                        placeholder="Set New Password"
                                        required
                                        // validate={{ required: { value: true } }}
                                      />

                                    {
                                      error?.password ? (
                                        <div className="invalid-feedback">
                                          {(password && password.trim() == "" || !password) ? "Please enter password" : "Password must be at least 8 chars, 1 capital, 1 small letter, 1 number and 1 sepacial charater."}
                                        </div>
                                      ) : null
                                    }

                                    </Col>

                                  </div>

                                  
                                  <div className="row justify-content-end">
                                    <Col sm={12} className="col-sm-12 text-end">
                                      <div>
                                        <Button
                                          type="button"
                                          color="primary"
                                          onClick={addUpdateUser}
                                        >
                                          {captainUser?._id?"Update User":"Add User"}
                                        </Button>

                                        <button
                                          type="button"
                                          onClick={() => {
                                            toggle();
                                          }}
                                          className="m-1 btn btn-secondary"
                                          data-dismiss="modal"
                                        >
                                          Close
                                        </button>

                                      </div>
                                    </Col>
                                  </div>
                                  
                                </CardText>
                              </Col>
                            </Row>
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
  else {
    return <Pages404 />
  }
};

EcommerceOrders.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
};

SectionTitle.prototype ={
  title: PropTypes.string
}

export default withRouter(EcommerceOrders);
