import { ADD_CITY, REMOVE_CITY, UPDATE_CITY, FETCH_CITIES, FETCH_CITIES_SUCCESS } from './actionTypes';

const INIT_STATE = {
  cities: [],
};

const City = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_CITY:
      return {
        ...state,
        cities: [...state.cities, action.payload],
      };
    case REMOVE_CITY:
      return {
        ...state,
        cities: state.cities.filter(city => city.id !== action.payload),
      };
    case UPDATE_CITY:
      return {
        ...state,
        cities: state.cities.map(city =>
          city.id === action.payload.id ? action.payload : city
        ),
      };
    case FETCH_CITIES_SUCCESS:
      console.log('FETCH_CITIES', action.payload);
      return {
        ...state,
        cities: action.payload,
      };
    default:
      return state;
  }
};

export default City;