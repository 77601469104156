import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Modal, Input, Label } from "reactstrap"
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap";
import MetaTags from 'react-meta-tags'
import { withRouter, Link } from "react-router-dom"
import Select from "react-select"
import toastr from "toastr";
import "toastr/build/toastr.min.css";
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import PropTypes from "prop-types"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import { useSelector, useDispatch } from "react-redux"
import { getReasons, getReasonsListSuccess, } from "../../store/actions"
import Switch from "react-switch"
import { postWeb } from "helpers/api_helper";
import { REASON_SEARCH } from "helpers/url_helper";
toastr.options = {
  positionClass: "toast-top-right",
  timeOut: 3000,
  extendedTimeOut: 1000,
  closeButton: true,
  debug: true,
  progressBar: true,
  preventDuplicates: true,
  newestOnTop: true,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
  showDuration: 300,
  hideDuration: 1000
};

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Off
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      On
    </div>
  )
}

const StatusComp = ({ row, updateStatus}) => {
  const [status, setStatus] = useState(row?.status || false)
  const [loading,setLoading] = useState(false)

  return (loading ? <p>Loading...</p> :<Switch
    disabled={loading == true ? true : false}
    uncheckedIcon={<Offsymbol />}
    checkedIcon={<OnSymbol />}
    onColor={"#626ed4"}
    onChange={async (val) => {
      setLoading(true)
      setStatus(val)
      await updateStatus({ ...row, status: val })
      setLoading(false)
    }
    }
    checked={status}
  />)
}

StatusComp.propTypes = {
  row: PropTypes.any,
  updateStatus: PropTypes.func
}

const Reasons = () => {
  const dispatch = useDispatch();
  const { refundReason } = useSelector(state => ({
    refundReason: state.Orders.reason
  }))
  const { SearchBar } = Search;
  const [reasonsList, setReasonsList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [error, setError] = useState({})
  const [addModal, setAddModal] = useState(false)
  const [selectedData, setSelectedData] = useState({})
  const [deleteConfirm, setDeleteConfirmModal] = useState(false)
  const [otherReason, setOtherReason] = useState(false)
  const [statusReason, setStatusReason] = useState(true)
  const [reasonType, setReasonType] = useState({})
  const [reasonAr, setReasonAr] = useState("")
  const [reasonEn, setReasonEn] = useState("")
  const [eventLoader, setEventLoader] = useState(false)
  const [fetchStatusTrue, setFetchStatusTrue] = useState(true)
  const [fetchStatusFalse, setFetchStatusFalse] = useState(true)

  useEffect(() => {
    dispatch(getReasons({}));
  }, [])
  const loadTimeslots = () => {
    let requestBody = {}
    if (fetchStatusTrue && fetchStatusFalse) {
      requestBody.status = { $in: [true, false] }
    } else if (fetchStatusTrue && !fetchStatusFalse) {
      requestBody.status = true
    } else if (fetchStatusFalse && !fetchStatusTrue) {
      requestBody.status = false
    } else if (!fetchStatusFalse && !fetchStatusTrue) {
      dispatch(
        getReasonsListSuccess(REASON_SEARCH, {
          record: [],
        })
      )
      return
    }
    dispatch(getReasons(requestBody))
  }

  useEffect(() => {
    loadTimeslots();
  }, [fetchStatusTrue, fetchStatusFalse])

  useEffect(() => {
    if (refundReason.length) {
      setReasonsList(refundReason);
    }
  }, [refundReason])
  const [statusUpdateLoading, setStatusUpdateLoading] = useState(false)
  const columns = [
    //   {
    //   dataField: 'id',
    //   text: 'Id',
    //   sort: true,
    // },
    {
      dataField: 'reasonEn',
      text: 'Reason En',
      classes: "reason-wrap",
      sort: false
    }, {
      dataField: "reasonAr",
      text: 'Reason Ar',
      classes: "reason-wrap",
      sort: false,
    }, {
      dataField: 'type',
      text: 'Type',
      sort: false,
    }, {
      dataField: "status",
      text: "Status",
      compact: true,
      formatter: (cellContent, row) => {
        return <StatusComp row={row} updateStatus={updateStatus} setDisiabled={setStatusUpdateLoading} disabled={statusUpdateLoading} />
      }

    }, {
      dataField: '',
      text: 'Action',
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleEdit(row)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
            </Link>
            {/* <Link
              to="#"
              className="text-danger"
              onClick={() => handleDelete(row)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
            </Link> */}
          </div>
        )
      }
    }];

  const defaultSorted = [{
    dataField: 'salary',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: reasonsList.length, // replace later with size(customers),
    custom: true,
  }
  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (reasonsList).length }
  ];

  const handleEdit = (item, onlyStatus) => {
    setIsEdit(true)
    let label = item?.type?.split("_")?.join(" ")
    label = label?.charAt(0)?.toUpperCase() + label?.slice(1);
    setSelectedData(item);
    setOtherReason(item?.isOther ? item.isOther : false)
    setStatusReason(item?.status)
    setReasonType({ value: item.type, label })
    setReasonAr(item.reasonAr)
    setReasonEn(item.reasonEn)
    if (!onlyStatus) setAddModal(true)
  }

  const handleDelete = (item) => {
    setSelectedData(item);
    setDeleteConfirmModal(true)
  }

  const deleteReason = () => {
    const apiData = {
      _id: selectedData?._id
    }
    // dispatch(deleteReasons(apiData))
    setDeleteConfirmModal(false)
  }

  const updateStatus = async (data) => {
    setStatusUpdateLoading(true)
    const response = await postWeb("reasons/update", data)
    if (response.statusCode == 400) {
      toastr.warning(response.msg, "Unable to update reason");
    }
    else if (response.statusCode == 200) {
      setAddModal(false);
      dispatch(getReasons());
      toastr.success("Reason has been updated", "Reason Update");
    }
    else {
      toastr.error("Reason is not updated", "Error Occured");
    }
    return
  }
  const handleReasonList = async () => {
    if (!reasonEn) {
      setError({ reasonEn: "Reason (English) is required" })
      return
    }
    if (!reasonAr) {
      setError({ reasonAr: "Reason (Arabic) is required" })
      return
    }
    if (!reasonType) {
      setError({ reasonType: "Reason type is required" })
      return
    }
    setEventLoader(true)
    setError({});
    let apiData = {
      reasonEn: reasonEn,
      reasonAr: reasonAr,
      isOther: otherReason,
      status: statusReason,
      type: reasonType.value
    }
    if (isEdit) {
      apiData["_id"] = selectedData._id
      const response = await postWeb("reasons/update", { ...apiData, _id: selectedData._id })
      if (response.statusCode == 400) {
        toastr.warning(response.msg, "Unable to update reason");
      }
      else if (response.statusCode == 200) {
        setAddModal(false);
        dispatch(getReasons());
        toastr.success("Reason has been updated", "Reason Update");
      }
      else {
        toastr.error("Reason is not updated", "Error Occured");
      }
      return
    } else {
      const response = await postWeb('/reasons/create', apiData);

      if (response.statusCode == 400) {
        toastr.warning(response.msg, "Unable to add Reason");
      }
      else if (response.statusCode == 200) {
        setAddModal(false);
        dispatch(getReasons());
        toastr.success("Reason has been added", "Reason Added");
      }
      else {
        toastr.error("Reason is not added", "Error Occured");
      }
    }
    setTimeout(() => {
      setAddModal(false)
    }, 500);
  }

  useEffect(() => {
    if (!addModal) {
      setEventLoader(false)
      setIsEdit(false)
      setOtherReason(false)
      setStatusReason(true)
      setReasonType("")
      setReasonAr("")
      setReasonEn("")
      setError({})
    }
  }, [addModal])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Reason List / Kleen admin</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Masters" breadcrumbItem="Reason List" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Default Datatable </CardTitle>
                  <p className="card-title-desc">
                    react-bootstrap-table-next plugin has most features enabled by default,
                    so all you need to do to use it with your own tables is to
                    call the construction function:{" "}
                    <code>react-bootstrap-table-next </code>.
                  </p> */}

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={columns}
                    data={reasonsList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={reasonsList}
                        search
                      >

                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2 justify-content-between">
                              <Col sm="3">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <div
                                className="d-flex align-items-center"
                                style={{ flexBasis: "content" }}
                              >
                                <div className="d-flex align-items-center mx-3">
                                  <span
                                    className={`me-2 ${fetchStatusTrue
                                      ? "text-success font-weight-semibold"
                                      : ""
                                      }`}
                                  >
                                    Show Enabled
                                  </span>
                                  <div className="form-check form-switch form-switch-lg">
                                    <input
                                      type="checkbox"
                                      className="form-check-input form-slider-color-green"
                                      defaultChecked={fetchStatusTrue}
                                      onChange={e => {
                                        setFetchStatusTrue(!fetchStatusTrue)
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="d-flex align-items-center me-5">
                                  <span
                                    className={`me-2 ${fetchStatusFalse
                                      ? "text-success font-weight-semibold"
                                      : ""
                                      }`}
                                  >
                                    Show Disabled
                                  </span>
                                  <div className="form-check form-switch form-switch-lg">
                                    <input
                                      type="checkbox"
                                      className="form-check-input form-slider-color-green"
                                      defaultChecked={fetchStatusTrue}
                                      onChange={e => {
                                        setFetchStatusFalse(!fetchStatusFalse)
                                      }}
                                    />
                                  </div>
                                </div><Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded mb-2 me-2"
                                  style={{
                                    width: "10rem",
                                  }}
                                  onClick={() => setAddModal(true)}
                                >
                                  <i className="mdi mdi-plus me-1" />
                                  Add Reasons
                                </Button>
                              </div>

                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>

                </CardBody>
              </Card>
              <Modal
                size="md"
                isOpen={addModal}
                toggle={() => setAddModal(!addModal)}
                centered
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    <span >
                      {isEdit ? "Update Reason" : "Add Reason"}
                    </span>
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      setAddModal(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col lg="12">
                      <div className="mb-3">
                        <Label>Reason (English) <span className="mandatory_feild">*</span></Label>
                        <Input
                          name="dryClean"
                          type="text"
                          className="form-control"
                          placeholder="Enter Reason (English)"
                          value={reasonEn}
                          onChange={(val) => setReasonEn(val.target.value)}
                        />
                        {
                          error?.reasonEn ? (
                            <Label style={{ color: "red" }}>{error.reasonEn}</Label>
                          ) : null
                        }
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="mb-3">
                        <Label>Reason (Arabic) <span className="mandatory_feild">*</span></Label>
                        <Input
                          name="dryClean"
                          type="text"
                          className="form-control"
                          placeholder="Enter Reason (Arabic)"
                          value={reasonAr}
                          onChange={(val) => setReasonAr(val.target.value)}
                        />
                        {
                          error?.reasonAr ? (
                            <Label style={{ color: "red" }}>{error.reasonAr}</Label>
                          ) : null
                        }
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="mb-3">
                        <Label>Type <span className="mandatory_feild">*</span></Label>
                        <Select
                          // isMulti={true}
                          defaultValue={reasonType.value}
                          value={reasonType}
                          onChange={(e) => setReasonType(e)}
                          options={[
                            { label: "Rating Positive", value: "rating_positive" },
                            { label: "Rating Negative", value: "rating_negative" },
                            { label: "Cancellation", value: "cancellation" },
                            { label: "Driver Updates", value: "driver_updates" },
                            { label: "Return Reasons", value: "return_reasons" },
                            { label: "Refund", value: "refund" },
                          ]}
                          classNamePrefix="select2-selection"
                        />
                        {
                          error?.reasonType ? (
                            <Label style={{ color: "red" }}>{error.reasonType}</Label>
                          ) : null
                        }
                      </div>
                    </Col>
                    <Col className="col-12 d-flex mb-4">
                      <div className="d-flex col-sm-3">
                        <label
                          htmlFor="horizontal-status-Input"
                          className="status-label mt-2"
                        >Other</label>
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="mx-2"
                          onColor="#626ed4"
                          onChange={() => {
                            setOtherReason(!otherReason)
                          }}
                          checked={otherReason}
                        />
                      </div>
                      <div className="d-flex col-sm-4">
                        <label
                          htmlFor="horizontal-status-Input1"
                          className="status-label mt-2"
                        >Status
                        </label>
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="mx-2"
                          onColor="#626ed4"
                          onChange={() => {
                            setStatusReason(!statusReason)
                          }}
                          checked={statusReason}
                        />
                      </div>
                    </Col>
                    <Col lg="12" className="text-end">
                      <Button
                        type="submit"
                        // color="primary"
                        className="w-md"
                        onClick={() => setAddModal(false)}
                      >
                        Cancel
                      </Button>
                      {
                        eventLoader ? (
                          <Button
                            color="primary"
                            className="btn-rounded mx-2"
                            type="button"
                            disabled
                          >
                            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2"></i>
                            Processing...
                          </Button>
                        ) : (
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md mx-2"
                            onClick={handleReasonList}
                          >
                            {isEdit ? "Update" : "Add"}
                          </Button>
                        )
                      }
                    </Col>
                  </Row>
                </div>

              </Modal>
              <Modal
                size="md"
                isOpen={deleteConfirm}
                toggle={() => setDeleteConfirmModal(!deleteConfirm)}
                centered
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    <span >
                      Delete Reason
                    </span>
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      setDeleteConfirmModal(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <h6>Are You sure to delete ?</h6>
                </div>
                <div className="modal-footer">
                  <Row>
                    <Col lg="12">
                      <Button
                        type="submit"
                        color="primary"
                        className="w-md"
                        onClick={() => deleteReason()}
                      >
                        Yes
                      </Button>
                      <Button
                        type="submit"
                        className="w-md mx-2"
                        onClick={() => setDeleteConfirmModal(false)}
                      >
                        No
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Modal>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment >
  )
}

export default Reasons