import { all, fork } from "redux-saga/effects"

//public
import ordersSaga from "./orders/saga"
import usersSaga from "./users/saga"
import LaundrySaga from "./laundry/saga"
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import ecommerceSaga from "./e-commerce/saga"
import calendarSaga from "./calendar/saga"
import chatSaga from "./chat/saga"
import cryptoSaga from "./crypto/saga"
import invoiceSaga from "./invoices/saga"
import projectsSaga from "./projects/saga"
import permissionSaga from "./permissions/saga"
import dataReportsSaga from "./data-reports/saga"
import tasksSaga from "./tasks/saga"
import mailsSaga from "./mails/saga"
import contactsSaga from "./contacts/saga";
import dashboardSaga from "./dashboard/saga";
import dashboardSaasSaga from "./dashboard-saas/saga";
import SliderSaga from "./slider/saga";
 
import promocodeSaga from "./promocode/saga";
//record
import RecordsSaga from "./record/saga";
import laundrySaga from "./laundries/saga"
import AreaSaga from "./areas/saga"
import driversSaga from "./drivers/saga"
import AnalyticsSaga from "./analytics/saga"
import ClothesSaga from "./clothes/saga"
import CouponSaga from "./coupon/saga"
import b2bSaga from "./b2bcustomer/saga"
import timeslotsSaga from "./timeslots/saga"
import customersSaga from "./customers/saga"
import b2bProductsSaga from "./b2bproduct/saga"
import loyalityPoinsSaga from "./loyalitypoints/saga"
import carwashOrdersSaga from "./carwashorders/saga"
import packagesSaga from "./packages/saga"
import ReasonSaga from "./reasons/saga"
import holidaySaga from "./holiday/saga"
import redeemPkgsSaga from "./redeem-packages/saga"
import serviceSaga from "./services/saga"
import settingsSaga from "./settings/saga"
import notificationTextSaga from "./notification-texts/saga"
import AnnouncementSaga from "./announcement/saga"
import InvoiceLogsSaga from "./invoiceLogs/saga"
import PosMaintananceSaga from "./posMaintanance/saga"
import VehiclesSaga from "./vehicles/saga"
import VendorsSaga from "./vendors/saga"
import LaundryWorkersSaga from "./laundryWorkers/saga"
import AccidentReportsSaga from "./accidentReports/saga"
import PartChangesSaga from "./partChanges/saga"
import citySaga from "./city/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AreaSaga),
    fork(laundrySaga),
    fork(usersSaga),
    fork(LaundrySaga),
    fork(ordersSaga),
    fork(AccountSaga),
    fork(promocodeSaga),
    fork(RecordsSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(mailsSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(projectsSaga),
    fork(permissionSaga),
    fork(dataReportsSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(driversSaga),
    fork(SliderSaga),
    fork(AnalyticsSaga),
    fork(ClothesSaga),
    fork(CouponSaga),
    fork(b2bSaga),
    fork(timeslotsSaga),
    fork(customersSaga),
    fork(b2bProductsSaga),
    fork(ReasonSaga),
    fork(loyalityPoinsSaga),
    fork(carwashOrdersSaga),
    fork(packagesSaga),
    fork(holidaySaga),
    fork(redeemPkgsSaga),
    fork(serviceSaga),
    fork(settingsSaga),
    fork(notificationTextSaga),
    fork(AnnouncementSaga),
    fork(InvoiceLogsSaga),
    fork(PosMaintananceSaga),
    fork(VehiclesSaga),
    fork(VendorsSaga),
    fork(LaundryWorkersSaga),
    fork(AccidentReportsSaga),
    fork(PartChangesSaga),
    fork(citySaga)
  ])
}
