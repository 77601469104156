import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Switch from "react-switch"
import classnames from "classnames"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Input,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  NavItem,
  NavLink,
  Nav, 
  TabContent, 
  TabPane
} from "reactstrap";
import { AvForm, AvField, Lable } from "availity-reactstrap-validation";
import { getLaundry, fetchLaundryList, postAddLaundry, postUpdateLaundry, postArea } from "helpers/LaundryHelper"
import { postAddUser } from "helpers/userHelper";
import { searchUsers,  updateNotificationText, getNotificationText, getNotificationTextSuccess } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
  getClothesList,
  getArealist,
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "store/actions";
import Pages404 from "pages/Utility/pages-404";
import { CREATE_NOTIFICATION_TEXTS, UPDATE_NOTIFICATION_TEXTS } from "helpers/url_helper";
import { postWeb } from "helpers/api_helper";
import Notifications from "./Notifications";

// import EcommerceOrdersModal from "./EcommerceOrdersModal";
const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Off
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      On
    </div>
  )
}

const NotificationTexts = props => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [enTitle, setEnTitle] = useState("");
  const [arTitle, setArTitle] = useState("");
  const [enBody, setEnBody] = useState("");
  const [arBody, setArBody] = useState("");
  const [value1, setvalue] = useState([]);
  const [consumer, setConsumer] = useState()
  const [availability, setAvailability] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [editableItem, setEditableItem] = useState({})
  const [error, setError] = useState({})
  const [userData, setUserData] = useState({});
  const [customerNotifications, setCustomerNotifications] = useState([]);
  const [adminNotifications, setAdminNotifications] = useState([]);
  const [laundryNotifications, setLaundryNotifications] = useState([]);
  const [captainNotifications, setCaptainNotifications] = useState([]);
  
  const [image, setImage] = useState("");
  
  const [customIconActiveTab, setcustomIconActiveTab] = useState("1")


  const { laundryList, notificationTexts, users } = useSelector(state => ({
    users: state.Users.users,
    notificationTexts: state.NotificationTexts.notificationTexts,
  }));

  useEffect(() => {
    if (!modal) {
      
      setAvailability([])
      setvalue([])
      setNotificationType("")
      setEnTitle("")
      setEnBody("")
      setArTitle("")
      setArBody("")
      setIsEdit(false)
      setEditableItem({})
    }
  }, [modal])


  const toggleIconCustom = tab => {
    if (customIconActiveTab !== tab) {
      setcustomIconActiveTab(tab)
    }
  }


  
  
  

  const handleOnchange = (val) => {
    setvalue(val ? val.split(",") : [])
  }



  useEffect(() => {
    const data = {}
    if(!users) dispatch(searchUsers({ webPermissions: true }))
     dispatch(getNotificationText())
  }, [])

  useEffect(() => {
    if (users.length > 0) {
      setUserData(users[0]);
    }
  }, [users])


  useEffect(() => {
    const _customerNotifications = [], 
      _adminNotifications = [],
      _laundryNotifications = [],
      _captainNotifications = [];

    notificationTexts.forEach((notification) => {
      if(notification?.consumer == "customer"){
        _customerNotifications.push(notification);
      }
      else if(notification?.consumer == "admin"){
        _adminNotifications.push(notification);
      }
      else if(notification?.consumer == "franchise_laundry"){
        _laundryNotifications.push(notification);
      }
      else if(notification?.consumer == "driver"){
        _captainNotifications.push(notification);
      }
    });


    setCustomerNotifications(_customerNotifications);
    setAdminNotifications(_adminNotifications);
    setLaundryNotifications(_laundryNotifications);
    setCaptainNotifications(_captainNotifications);


  }, [notificationTexts])

  const selectRow = {
    mode: "checkbox",
  };

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: notificationTexts.length, // replace later with size(orders),
    custom: true,
  };
  

  const handleEditCloth = (item) => {
    setEditableItem(item)
    setIsEdit(true);
    
    setNotificationType(item.notificationType)
    setArTitle(item.arTitle)
    setEnTitle(item.enTitle)
    setArBody(item.arBody)
    setEnBody(item.enBody)
    setConsumer(item.consumer)

    setModal(true)
  }

  useEffect(() => {
    if (!modal) {
      setError({})
    }
  }, [modal])

  

  

  const toggle = () => {
    setModal(!modal);
  };

  const submitNotificationText = () => {
    
    
    const _id = editableItem && editableItem._id; 

    if (isEdit && _id) {
      const data = {
        enTitle: enTitle,
        enBody: enBody,
        arTitle: arTitle,
        arBody: arBody
      };

      postWeb( UPDATE_NOTIFICATION_TEXTS, {
        _id : _id, ...data
      }).then((response) => {
        if(response && response.statusCode == 200){
          toastr.success(`Notification Text Updated Successfully`, `Success`);
          dispatch(getNotificationText())
        }
        else{
          toastr.error(`Unable to update notification text`, `Error`);
        }
      });
      
    } else {
      const data = {
        notificationType: notificationType,
        enTitle: enTitle,
        enBody: enBody,
        arTitle: arTitle,
        arBody: arBody,
        consumer: consumer
      };
      
      postWeb(CREATE_NOTIFICATION_TEXTS, data).then((response) => {
        if(response && response.statusCode == 200){
          toastr.success(`Notification Text Updated Successfully`, `Success`);
          dispatch(getNotificationText())
        }
        else{
          toastr.error(`Unable to update notification text`, `Error`);
        }
      });
    
    }
    toggle()
  }

  var node = useRef();
  const handleOrderClicks = () => {
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "orderID",
      order: "desc",
    },
  ];

  if (userData?.permissions?.allPermissions == true || userData?.permissions?.notificationText?.read == true) {
    return (
      <React.Fragment>
        {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
        <div className="page-content">
          <MetaTags>
            <title>Notification Texts</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Master" breadcrumbItem="Notification Texts" />
            <Card>
              <CardBody>
              <Nav className="icon-tab nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customIconActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleIconCustom("1")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-user"></i> Customer
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customIconActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleIconCustom("2")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-briefcase"></i> Admin
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-user"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customIconActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleIconCustom("3")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-shopping-basket"></i> Laundry
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-shopping-basket"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customIconActiveTab === "4",
                        })}
                        onClick={() => {
                          toggleIconCustom("4")
                        }}
                      >
                        <span className="d-none d-sm-block">
                          <i className="fas fa-car-side"></i> Driver
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-car-side"></i>
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent
                    activeTab={customIconActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                        <Notifications 
                          notificationData={customerNotifications}
                          userData={userData}
                          onViewClick={(item) => {handleEditCloth(item)}}
                        />
                    </TabPane>
                    <TabPane tabId="2">
                        <Notifications 
                          notificationData={adminNotifications}
                          userData={userData}
                          onViewClick={(item) => {handleEditCloth(item)}}
                        />
                    </TabPane>
                    <TabPane tabId="3">
                        <Notifications 
                          notificationData={laundryNotifications}
                          userData={userData}
                          onViewClick={(item) => {handleEditCloth(item)}}
                        />
                    </TabPane>
                    <TabPane tabId="4">
                        <Notifications 
                          notificationData={captainNotifications}
                          userData={userData}
                          onViewClick={(item) => {handleEditCloth(item)}}
                        />
                    </TabPane>

                </TabContent>


              </CardBody>
            </Card>
            <Modal isOpen={modal} toggle={toggle} size="md">
              <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? "Edit Notification Text" : "Add Notification Text"}
              </ModalHeader>
              <ModalBody>
                <AvForm
                  onValidSubmit={submitNotificationText}
                >
                  <Row form>
                    <div className="row mb-2">
                      <Col className="col-12">
                       

                        <div className="mb-3">
                          <AvField
                            name="notificationType"
                            label={<div>Notification Type <span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="notification type required"
                            validate={{
                              required: { value: true },
                            }}
                            disabled
                            onChange={(e) => { setNotificationType(e.target.value) }}
                            value={notificationType}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="enTitle"
                            label={<div>Notification Title (English) <span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Notification Title in English is Required"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={(e) => { setEnTitle(e.target.value) }}
                            value={enTitle}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="enBody"
                            label={<div>Notification Body (English) <span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Notification Body in English is Required"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={(e) => { setEnBody(e.target.value) }}
                            value={enBody}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="arTitle"
                            label={<div>Title (Arabic) <span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Notification TTitle in Arabic is Required"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={(e) => { setArTitle(e.target.value) }}
                            value={arTitle}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="arBody"
                            label={<div>Notification Body (Arabic) <span className="mandatory_feild">*</span></div>}
                            type="text"
                            errorMessage="Notification Body in Arabic is Required"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={(e) => { setArBody(e.target.value) }}
                            value={arBody}
                          />
                        </div>


                        
                        <div className="mb-3">
                          <label>Consumer<span className="mandatory_feild">*</span></label>
                          <AvField 
                            type="select"
                            name="consumer"
                            value={consumer}
                            errorMessage="Select Consumer"
                            className="form-control"
                            onChange={(e) =>setConsumer(e.target.value)}
                            validate={{ required: { value: true } }}
                            helpMessage="">
                                      <option value="">Selet Consumer</option>
                                      <option value="customer" selected>Customer</option>
                                      <option value="admin">Admin</option>
                                      <option value="driver">Driver</option>
                                      <option value="franchise_laundry">Franchise Laundry</option>
                            </AvField>
                        </div>

                       
                      </Col>

                    </div>

                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </ModalBody>
            </Modal>


          </Container>
        </div>
      </React.Fragment>
    );
  }
  else {
    return <Pages404 />
  }
};

NotificationTexts.propTypes = {
  
};

export default withRouter(NotificationTexts);
