import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchCitiesSuccess, fetchCitiesFail } from './actions';
import { FETCH_CITIES } from './actionTypes';
import { fetchCityDataApi } from '../../helpers/cityHelper';

function* fetchCityData(action) {
  try {
    const response = yield call(fetchCityDataApi, action.payload);
    yield put(fetchCitiesSuccess(response.data));
  } catch (error) {
    yield put(fetchCitiesFail(error.message));
  }
}

function* citySaga() {
  yield takeLatest(FETCH_CITIES, fetchCityData);
}

export default citySaga;